import React, { useContext } from "react";
import { Stats } from "../../models/Stats";
import { Formatter, UnitMetadata, unitToSigned } from "../../utils/Formatter";
import { StatsRowValue } from "./StatsRowValue";
import { StatsSubSection } from "./StatsSection";
import { FieldValues, UseFormSetFocus } from "react-hook-form";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { Edge, Node } from "../../models/Dfg";
import { buildKpiSpotlightId, getKpiDefinition, getKpiSpotlightIdNode, getUnit, KpiDefinition } from "../../models/Kpi";
import { getEdgeKpiStatistics, getNodeKpiStatistics, getEquipmentNodeKpiStatistics } from "../../utils/DfgUtils";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import VarianceStatistics from "../variance-statistics/VarianceStatistics";
import { EquipmentNodeStatisticsSchema } from "../../models/ApiTypes";

type SideStatsProps = {
    kpiType: KpiTypes,
    title?: string,
    isLoading?: boolean,
    disableMinMax?: boolean,
    addSign?: boolean,
    disableSum?: boolean,
    disableMedian?: boolean,
    spotlightSuffixes?: string[],
}

export function NodeStats(props: SideStatsProps & { node: Node | undefined, equipmentStats?: EquipmentNodeStatisticsSchema | undefined }) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinition = getKpiDefinition(props.kpiType, { session, settings });

    const isVarianceAllowed = kpiDefinition?.allowedStatistics.includes(StatisticTypes.Variance);

    const unitMean = props.addSign ? unitToSigned(getUnit(kpiDefinition?.unit, StatisticTypes.Mean)) : getUnit(kpiDefinition?.unit, StatisticTypes.Mean);
    const unitSum = props.addSign ? unitToSigned(getUnit(kpiDefinition?.unit, StatisticTypes.Sum)) : getUnit(kpiDefinition?.unit, StatisticTypes.Sum);

    if (kpiDefinition === undefined)
        return null;

    const stats = getStats(session, settings, kpiDefinition, props.node, props.equipmentStats);

    const meanOptions = {
        isLoading: props.isLoading,
        unit: unitMean,
        formatterParams: { locale: session.locale, baseQuantity: settings.quantity },
    };

    const spotlightSuffixes = props.spotlightSuffixes ? ["Node", ...props.spotlightSuffixes] : ["Node"];

    if (isVarianceAllowed && !props.disableMedian && !props.disableMinMax)
        return <StatsSubSection title={props.title ?? kpiDefinition?.label} disableTable={true} spotlightId={getKpiSpotlightIdNode(session, kpiDefinition.id, spotlightSuffixes)}>
            <VarianceStatistics
                disableMedian={props.disableMedian}
                variance={stats}
                mean={stats.mean}
                sum={stats.sum}
                unit={kpiDefinition?.unit}
                addSign={props.addSign}
            />
        </StatsSubSection>;

    return <StatsSubSection title={props.title ?? kpiDefinition?.label} spotlightId={getKpiSpotlightIdNode(session, kpiDefinition.id, spotlightSuffixes)}>
        {!props.disableSum && <StatsRowValue label="common.statistics.sum" value={stats?.sum} isLoading={props.isLoading} unit={unitSum} isHighlight={(settings.kpi.statistic === StatisticTypes.Sum) || stats.mean === undefined} />}
        {<StatsRowValue label="common.mean" value={stats?.mean} {...meanOptions} isHighlight={settings.kpi.statistic === StatisticTypes.Mean} />}
        {!props.disableMedian && <StatsRowValue label="common.statistics.median" value={stats?.median} {...meanOptions} isHighlight={[StatisticTypes.Median, StatisticTypes.Variance].includes(settings.kpi.statistic)} />}
        {!props.disableMinMax && <StatsRowValue label="common.statistics.max" value={stats?.max} {...meanOptions} />}
        {!props.disableMinMax && <StatsRowValue label="common.statistics.min" value={stats?.min} {...meanOptions} />}
    </StatsSubSection>;
}

export function EdgeStats(props: React.PropsWithChildren & SideStatsProps & { edge: Edge | undefined }) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinition = getKpiDefinition(props.kpiType, { session, settings });

    const isVarianceAllowed = kpiDefinition?.allowedStatistics.includes(StatisticTypes.Variance);

    // so far we always use sum for edge stats
    const unit = getUnit(kpiDefinition?.unit, StatisticTypes.Sum);

    if (kpiDefinition === undefined)
        return null;

    const stats = props.edge ? getEdgeKpiStatistics(session, settings, props.edge, kpiDefinition.id) : {};

    const meanOptions = {
        isLoading: props.isLoading,
        unit: unit,
        formatterParams: { locale: session.locale, baseQuantity: settings.quantity },
    };

    if (isVarianceAllowed && !props.disableMedian && !props.disableMinMax)
        return <StatsSubSection title={props.title ?? kpiDefinition?.label} disableTable={true} spotlightId={buildKpiSpotlightId(kpiDefinition.id, ["Edge"])}>
            <VarianceStatistics
                isLoading={props.isLoading}
                disableMedian={props.disableMedian}
                variance={stats}
                mean={stats.mean}
                sum={stats.sum}
                unit={kpiDefinition?.unit}
                addSign={props.addSign}
            />
        </StatsSubSection>;

    return <StatsSubSection title={props.title ?? kpiDefinition?.label} spotlightId={buildKpiSpotlightId(kpiDefinition.id, ["Edge"])}>
        {!props.disableSum && <StatsRowValue label="common.statistics.sum" value={stats?.sum} isLoading={props.isLoading} unit={unit} isHighlight={(settings.kpi.statistic === StatisticTypes.Sum) || stats.mean === undefined} />}
        <StatsRowValue label="common.mean" value={stats?.mean} {...meanOptions} isHighlight={settings.kpi.statistic === StatisticTypes.Mean} />
        {!props.disableMedian && <StatsRowValue label="common.statistics.median" value={stats?.median} {...meanOptions} isHighlight={([StatisticTypes.Median, StatisticTypes.Variance].includes(settings.kpi.statistic))} />}
        {!props.disableMinMax && <StatsRowValue label="common.statistics.max" value={stats?.max} {...meanOptions} />}
        {!props.disableMinMax && <StatsRowValue label="common.statistics.min" value={stats?.min} {...meanOptions} />}
        {props.children}
    </StatsSubSection>;
}

function getStats(session: SessionContextType, settings: SettingsContextType, kpiDefinition: KpiDefinition, node: Node | undefined, equipmentStats?: EquipmentNodeStatisticsSchema | undefined): Stats {

    if (kpiDefinition.isEquipmentStatsKpi) {
        return equipmentStats ? getEquipmentNodeKpiStatistics(session, settings, equipmentStats, kpiDefinition.id) : {};
    } else {
        return node ? getNodeKpiStatistics(session, settings, node, kpiDefinition.id) : {};
    }
}

export function FrequencyAndProductStats(props: { node: Node | undefined, kpi?: KpiTypes, isLoading?: boolean }) {

    return <>
        <StatsSubSection title="common.passCount" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Node"])}>
            <StatsRowValue label="common.statistics.sum" isHighlight={props.kpi === KpiTypes.Frequency} value={props.node?.frequencyStatistics?.sum} unit={Formatter.units.number} isLoading={props.isLoading} />
        </StatsSubSection>
        <ProductStats node={props.node} isLoading={props.isLoading} />
    </>;
}

export function FrequencyStats(props: { node: Node | undefined, kpi?: KpiTypes, isLoading?: boolean }) {
    return <>
        <StatsSubSection title="common.passCount" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Node"])}>
            <StatsRowValue label="common.statistics.sum" isHighlight={props.kpi === KpiTypes.Frequency} value={props.node?.frequencyStatistics?.sum} unit={Formatter.units.number} isLoading={props.isLoading} />
        </StatsSubSection>
    </>;
}

export function EdgeFrequencyStats(props: { edge: Edge | undefined, isLoading?: boolean }) {
    return <StatsSubSection title="common.actionFrequency" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge"])}>
        <StatsRowValue label="common.statistics.absCount" isHighlight={true} value={props.edge?.frequencyStatistics?.sum} unit={Formatter.units.number} />
    </StatsSubSection>;
}

export function ProductStats(props: { node: Node | undefined, kpi?: KpiTypes, isLoading?: boolean}) {
    return <StatsSubSection title="common.productCount" spotlightId={buildKpiSpotlightId(KpiTypes.ProductCount, ["Node"])}>
        <StatsRowValue label="common.statistics.sum" isHighlight={props.kpi === KpiTypes.ProductCount} value={props.node?.activityValues?.product?.nUnique} unit={Formatter.units.number} isLoading={props.isLoading} />
    </StatsSubSection>;
}


export function MeanMinMaxStats(props: { title: string, stats: Stats, unit: UnitMetadata, isLoading?: boolean }) {
    const options = {
        isLoading: props.isLoading,
        unit: props.unit
    };
    return <StatsSubSection title={props.title} >
        <StatsRowValue label="common.statistics.mean" value={props.stats.mean} isHighlight={true} {...options} />
        <StatsRowValue label="common.statistics.min" value={props.stats.min} {...options} />
        <StatsRowValue label="common.statistics.max" value={props.stats.max} {...options} />
    </StatsSubSection>;
}

export function SumMeanMinMaxStats(props: { title: string, stats: Stats, unit: UnitMetadata, isLoading?: boolean }) {
    const options = {
        isLoading: props.isLoading,
        unit: props.unit
    };
    return <StatsSubSection title={props.title} >
        <StatsRowValue label="common.statistics.total" value={props.stats.sum} {...options} />
        <StatsRowValue label="common.statistics.mean" value={props.stats.mean} isHighlight={true} {...options} />
        <StatsRowValue label="common.statistics.min" value={props.stats.min} {...options} />
        <StatsRowValue label="common.statistics.max" value={props.stats.max} {...options} />
    </StatsSubSection>;
}

/**
 * Executes the provided function when the user presses enter.
 * Example: <input {...formEnterExecute(() => console.log("enter pressed"))} />
 * @param onEnter Callback function
 */
export function formEnterExecute<T>(onEnter: () => void) {
    return {
        onKeyDown: (e: React.KeyboardEvent<T>) => {
            if (e.key === "Enter") {
                e.preventDefault();
                onEnter();
            }
        }
    };
}

/**
 * Attach this to your input fields to focus the next field when the user presses enter.
 * Example: 
 * const { register, setFocus, ... } = useForm<FormData>({ ... });
 * <input {...formEnterFocus(setFocus, "nextField")} />
 * @param focusFunc setFocus, as retrieved from useForm()
 * @param target name of the target element
 */
export function formEnterFocus<T, K extends FieldValues>(focusFunc: UseFormSetFocus<K>, target: any) {
    return {
        onKeyDown: (e: React.KeyboardEvent<T>) => {
            if (e.key === "Enter") {
                e.preventDefault();
                focusFunc(target, {
                    shouldSelect: true
                });
            }
        }
    };
}

/**
 * Function that returns whether a statistic is highlighted or not.
 */
export function isHighlightedStats(statistic: StatisticTypes, settings: SettingsType) {
    return statistic === settings.kpi.statistic;
}
/**
 * Function that returns whether a kpi value is highlighted or not.
 */
export function isHighlighted(kpi: KpiTypes, settings: SettingsType) {
    return kpi === settings.kpi.selectedKpi;
}