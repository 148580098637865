import React, { useContext } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { getRouteFromLocation } from "../../Routing";
import { BreadcrumbsType, TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { LegacyAnalyzedValues, RcaType } from "../../contexts/ContextTypes";
import { SessionContext, SessionContextType, isOniqEmployee } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, emptyRcaState, getRecentRcaByType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { QuantityType, getAssignedQuantities, isBaseQuantityType } from "../../utils/Quantities";
import { getRcaSelectionType } from "./RcaUtils";
import RootCauseAnalysis from "./RootCauseAnalysis";
import SideStatisticsRcaStats from "./stats/SideStatisticsRcaStats";

export function RootCauseView() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const rcaBaseQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.id).filter(isBaseQuantityType);

    // Show planning data option when case- or machine scrap data is available in planning data,
    // and the same quantity is available for actual case- or machine data.
    const scrapQuantities = getAssignedQuantities(session.project?.eventKeys, [QuantityType.CaseScrap, QuantityType.Scrap], false).map(q => q.baseQuantity);

    const isOniqUser = isOniqEmployee(session);

    const rcaPages: TabbedViewType[] = [{
        isVisible: isRcaVisible(RcaType.Time, session, isOniqUser),
        tabTitle: "common.scheduleDeviation",
        tabSlug: "deviation",
        spotlightId: "Timings-PD-RCA",
        statsSpotlightId: getRecentRcaByType(RcaType.Time, settings)?.status !== "finished" ? undefined : getRcaSelectionType(settings.selection) ? "Timings-PD-RCA-Statistics-Selection" : "Timings-PD-RCA-Statistics-NoSelection",
        stats: getRecentRcaByType(RcaType.Time, settings)?.result !== undefined ? <SideStatisticsRcaStats type={RcaType.Time} /> : null,
        selectionTrigger: (settings) => {
            return !!getRcaSelectionType(settings.selection);
        },
        disableSharing: isRcaResultsPage(RcaType.Time, settings),
        controller: undefined,
        content: <>
            <RootCauseAnalysis
                title="rca.titleDeviationRca"
                type={RcaType.Time} />
        </>,
    }, {
        // throughput time
        isVisible: isRcaVisible(RcaType.ThroughputTime, session, isOniqUser),
        tabTitle: "common.throughputTime",
        tabSlug: "throughput-time",
        activator: (preferences) => {
            settings.set(viewSettingsInitialization(session, settings, preferences, undefined, {
                analyses: [LegacyAnalyzedValues.TimeValuesMean],
                quantities: getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.baseQuantity),
            }));
        },
        disableSharing: isRcaResultsPage(RcaType.ThroughputTime, settings),
        selectionTrigger: (settings) => {
            return (settings.selection.node ||
                settings.selection.edge ||
                settings.selection.machine ||
                settings.selection.feature ||
                settings.selection.product ||
                settings.selection.category ||
                settings.selection.categoryValue) !== undefined;
        },
        statsSpotlightId: getRecentRcaByType(RcaType.ThroughputTime, settings)?.status !== "finished" ? undefined : "Timings-Variance" + (getRcaSelectionType(settings.selection) ? "-RCA-Statistics-Selection" : "-RCA-Statistics-NoSelection"),
        stats: getRecentRcaByType(RcaType.ThroughputTime, settings)?.result !== undefined ? <SideStatisticsRcaStats type={RcaType.ThroughputTime} /> : undefined,
        controller: undefined,
        spotlightId: "Timings-Variance-RCA",
        content: <>
            <RootCauseAnalysis
                type={RcaType.ThroughputTime}
                title={"rca.titleThroughputTimeRca"}
                baseQuantities={rcaBaseQuantities}
                analyzedValue={LegacyAnalyzedValues.TimeValuesMean}
            />
        </>,
    }, {
        isVisible: isRcaVisible(RcaType.Throughput, session, isOniqUser),
        tabTitle: "common.throughput",
        tabSlug: "throughput",
        disableSharing: isRcaResultsPage(RcaType.Throughput, settings),
        activator: (preferences) => {
            settings.set(viewSettingsInitialization(session, settings, preferences, undefined, {
                analyses: [LegacyAnalyzedValues.OutputRate],
                quantities: getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.baseQuantity),
            }));
        },
        selectionTrigger: (settings) => {
            return (settings.selection.node ||
                settings.selection.edge ||
                settings.selection.machine ||
                settings.selection.feature ||
                settings.selection.product ||
                settings.selection.category ||
                settings.selection.categoryValue) !== undefined;
        },
        statsSpotlightId: getRecentRcaByType(RcaType.Throughput, settings)?.status !== "finished" ? undefined : "Output-Variance" + (getRcaSelectionType(settings.selection) ? "-RCA-Statistics-Selection" : "-RCA-Statistics-NoSelection"),
        stats: getRecentRcaByType(RcaType.Throughput, settings)?.result !== undefined ? <SideStatisticsRcaStats type={RcaType.Throughput} /> : undefined,
        controller: undefined,
        spotlightId: "Output-Variance-RCA",
        content: <>
            <RootCauseAnalysis
                type={RcaType.Throughput}
                title={"rca.titleThroughputRca"}
                baseQuantities={rcaBaseQuantities}
                analyzedValue={LegacyAnalyzedValues.OutputRate}
            />
        </>,
    }, {
        isVisible: isRcaVisible(RcaType.Quality, session, isOniqUser),
        disableSharing: isRcaResultsPage(RcaType.Quality, settings),
        tabTitle: "common.scrap",
        tabSlug: "quality",
        controller: undefined,
        activator: (preferences) => {
            settings.set(viewSettingsInitialization(session, settings, preferences, undefined, {

                quantities: scrapQuantities,
                analyses: [LegacyAnalyzedValues.QualityValuesSum, LegacyAnalyzedValues.QualityQuota],
            }));
        },
        selectionTrigger: (settings) => {
            return (settings.selection.node ||
                settings.selection.machine ||
                settings.selection.category ||
                settings.selection.product ||
                settings.selection.feature ||
                settings.selection.categoryValue) !== undefined;
        },
        statsSpotlightId: getRecentRcaByType(RcaType.Time, settings)?.status !== "finished" ? undefined :
            getRcaSelectionType(settings.selection) ? "Quality-QD-RCA-Statistics-Selection" : "Quality-QD-RCA-Statistics-NoSelection",
        stats: getRecentRcaByType(RcaType.Quality, settings)?.result !== undefined ? <SideStatisticsRcaStats type={RcaType.Quality} /> : undefined,
        spotlightId: "Quality-QD-RCA",
        content: <>
            <RootCauseAnalysis
                title="rca.titleScrapRca"
                type={RcaType.Quality}
                baseQuantities={scrapQuantities}
            />
        </>,
    }];

    const breadcrumbs: BreadcrumbsType[] = [{
        label: "common.analyses",
        url: `/projects/${session.projectId}/analyses`,
    }, {
        label: "common.rootCauses"
    }];

    const { pathname } = useLocation();
    const match = matchPath(getRouteFromLocation(pathname).route!, pathname);

    // When we're showing results, add the results breadcrumb.
    [{
        type: RcaType.Time,
        label: "common.scheduleDeviation"
    }, {
        type: RcaType.ThroughputTime,
        label: "common.throughputTime"
    }, {
        type: RcaType.Throughput,
        label: "common.throughput"
    }, {
        type: RcaType.Quality,
        label: "common.scrap"
    }].forEach((r, idx) => {
        if (isRcaResultsPage(r.type, settings) && match?.params?.tabSlug === rcaPages[idx].tabSlug) {
            const rcas = settings.rcaStates[r.type];

            // Add "back" functionality to the last breadcrumb.
            breadcrumbs[breadcrumbs.length - 1].state = {
                ...settings,
                filters: rcas?.[0].rcaFilters,
                rcaStates: {
                    ...settings.rcaStates,
                    [r.type]: [rcas !== undefined && rcas.length > 0 ? {
                        ...rcas[0],
                        showResults: false,
                    } : emptyRcaState],
                },
                history: [],
            };

            breadcrumbs.push({
                label: r.label,
            });

            // if we have a drilldown, add a breadcrumb for it and add reset state
            const rca = getRecentRcaByType(r.type, settings);
            if (rca.drilldown) {
                breadcrumbs[breadcrumbs.length - 1].state = {
                    ...settings,
                    filters: rcas?.[0].rcaFilters,
                    rcaStates: {
                        ...settings.rcaStates,
                        [r.type]: rcas !== undefined && rcas.length > 1 ? [rcas[0]] : rcas!,
                    },
                    history: [],
                };

                breadcrumbs.push({ label: "rca.startDrilldown" });
            }
        }
    });

    return <TabbedView
        subtitle={i18n.t("common.rootCauseAnalyses").toString()}
        breadcrumbs={breadcrumbs}
        pages={rcaPages}
    />;
}

export function isRcaVisible(type: RcaType, session: SessionContextType, isOniqUser: boolean | undefined) {
    const hasScrapData = getAssignedQuantities(session.project?.eventKeys, [QuantityType.Scrap, QuantityType.CaseScrap], false).length > 0;
    const hasYieldData = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).length > 0;
    const hasPlanningData = session.project?.eventKeysPlan !== undefined;

    switch (type) {
        case RcaType.Bottleneck:
            // We always want to show the bottleneck RCA unless it is explicitly disabled.
            return hasYieldData && (session.project?.features?.allowBottleneckRca !== false);
        case RcaType.Quality:
            return hasScrapData && (session.project?.features?.allowQualityRca || isOniqUser);
        case RcaType.Throughput:
            return hasYieldData && (session.project?.features?.allowThroughputRca || isOniqUser);
        case RcaType.ThroughputTime:
            return session.project?.features?.allowThroughputTimeRca || isOniqUser;
        case RcaType.Time:
            return hasPlanningData && (session.project?.features?.allowScheduleDeviationRca || isOniqUser);
        case RcaType.OrgLosses:
            return (session.project?.features?.allowOrganizationalLossesAnalysis || isOniqUser) &&
                session.project?.eventKeys?.isInterruption !== undefined &&
                session.project?.eventKeys?.reason !== undefined;
    }
}

export function isRcaResultsPage(rcaType: RcaType, settings: SettingsType) {
    const rca = getRecentRcaByType(rcaType, settings);
    return rca.id !== undefined && rca.showResults === true;
}