import React, { useContext } from "react";
import { StatsRowValueSigned } from "../../components/stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { NodeStats, ProductStats, isHighlighted, isHighlightedStats } from "../../components/stats-section/Utility";
import { ValueSpinner } from "../../components/value-spinner/ValueSpinner";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { BaseGraph, CASE_TYPE_ID, Edge, groupingKeysNoneGroups, Node } from "../../models/Dfg";
import { Formatter } from "../../utils/Formatter";
import { DfgUtils, getCustomKpisDfg, isTerminalNode } from "../../utils/DfgUtils";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { isObjectCentricDeviationAvailable } from "../../utils/SettingsUtils";
import { LocalizationInfoStats } from "../../components/side-statistics/LocalizationInfoStats";
import { getEdgeObjectTypes, getNodeObjectTypes } from "../../components/controls/ObjectControls";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { StatsCalculationRequest } from "../../models/ApiTypes";
import { buildKpiSpotlightId, getKpiDefinition } from "../../models/Kpi";
import { ApiModes, useUnifiedDeviationGraphs } from "../../hooks/UseUnifiedDeviationGraph";

export default function SideStatisticsGraphDeviation() {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const graphOptions: Partial<StatsCalculationRequest> = {
        ...getCustomKpisDfg(settings, session, true),
        calculateUnknownStats: true,
        useActivityPasses: true,
        calculateNodes: true,
        calculateEdges: true,
        calculateTimeAndFreqStats: true,
        calculateActivityValues: true,
    };
    const [ actual, planned, deviation, isLoading, apiMode ] = useUnifiedDeviationGraphs(graphOptions);

    const isObjectCentricDeviationAllowed = session.project !== undefined && isObjectCentricDeviationAvailable(session.project);
    const disableProcessFocus = !isObjectCentricDeviationAllowed;

    const objectType = disableProcessFocus ? CASE_TYPE_ID : getObjectType(settings);

    const selectedEdge = (settings.selection.edge ? DfgUtils.findObjectEdge(settings.selection.edge, isObjectCentricDeviationAllowed, objectType) : undefined);
    const selectedNode = (settings.selection.node ? DfgUtils.findObjectNode(settings.selection.node, isObjectCentricDeviationAllowed, objectType) : undefined);

    const matchNode = (n1: Node, n2?: Node) => (n1.id === n2?.id);
    const findSelectedNode = (graph: BaseGraph | undefined) => {
        const matchedNode = graph?.nodes.find(n => matchNode(n, selectedNode));
        const matchedObjectNode = matchedNode ? DfgUtils.findObjectNode(matchedNode, isObjectCentricDeviationAllowed, objectType) : undefined;
        if (!matchedObjectNode?.id)
            return undefined;
        return matchedObjectNode;
    };

    const matchEdge = (e1: Edge, e2?: Edge) => (e1.from === e2?.from && e1.to === e2?.to && e1.objectType === e2?.objectType);
    const findSelectedEdge = (graph: BaseGraph | undefined) => graph?.edges.find(e => matchEdge(e, selectedEdge));

    const plannedNode = findSelectedNode(planned);
    const actualNode = findSelectedNode(actual);
    const deviationNode = findSelectedNode(deviation);
    const plannedEdge = findSelectedEdge(planned);
    const actualEdge = findSelectedEdge(actual);
    const deviationEdge = findSelectedEdge(deviation);

    const label = selectedEdge !== undefined ?
        `${DfgUtils.getNodeLabelById(selectedEdge.from, actual?.nodes, settings.groupingKey) ??
        DfgUtils.getNodeLabelById(selectedEdge.from, planned?.nodes, settings.groupingKey)} ➞
         ${DfgUtils.getNodeLabelById(selectedEdge.to, actual?.nodes, settings.groupingKey) ??
        DfgUtils.getNodeLabelById(selectedEdge.to, planned?.nodes, settings.groupingKey)}`
        : getShortActivityLabelFromActivityValues(selectedNode?.activityValues, settings.groupingKey);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const isTerminalNodeSelected = selectedNode ? Boolean(isTerminalNode(selectedNode)) : false;

    const nodePlanWithoutExecution = selectedNode !== undefined && !!plannedNode && !actualNode;
    const nodeUnplannedExecution = selectedNode !== undefined && !!actualNode && !plannedNode;

    const edgePlanWithoutExecution = selectedEdge !== undefined && !!plannedEdge && !actualEdge;
    const edgeUnplannedExecution = selectedEdge !== undefined && !!actualEdge && !plannedEdge;


    return <div data-testid="sidePanel">

        {selectedNode !== undefined && !isTerminalNodeSelected && ((plannedNode !== undefined && actualNode !== undefined) || isLoading) &&
            <StatsSection title={label} >
                {/* Dropdown for object centric */}
                <LocalizationInfoStats />
                {renderNodeStatistics(deviationNode)}
                {/* Frequency stats node */}
                {apiMode !== ApiModes.Routings && <StatsSubSection title="common.passCount" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Node", "Deviation"])}>
                    <StatsRowValueSigned label="common.difference" isHighlight={isHighlighted(KpiTypes.Frequency, settings)} value={deviationNode?.frequencyStatistics?.sum} unit={Formatter.units.numberShort} />
                </StatsSubSection>}
                <ProductStats node={actualNode} kpi={settings.kpi.selectedKpi}/>
            </StatsSection>}
        {!isLoading && nodeUnplannedExecution && <div className="section">
            <div className="title">{label}</div>
            {/* Dropdown for object centric */}
            <LocalizationInfoStats />
            {renderNodeStatistics(actualNode)}
            <StatsSubSection title="workflows.planningDeviation.PlanningDeviationPasses" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Node", "Deviation"])}>
                <StatsRowValueSigned label={"common.difference"} value={actualNode?.frequencyStatistics?.sum} unit={Formatter.units.numberShort} />
            </StatsSubSection>
            <ProductStats node={actualNode} />
            <div className="explanation">{i18n.t("workflows.planningDeviation.noDataExplanation")}</div>
        </div>}

        {!isLoading && nodePlanWithoutExecution && <div className="section">
            <div className="title">{label}</div>
            {/* Dropdown for object centric */}
            <LocalizationInfoStats />
            <div className="explanation">{i18n.t("workflows.planningDeviation.planNotExecutedExplanationNode")}</div>
        </div>}

        {selectedEdge !== undefined && (isLoading || (plannedEdge !== undefined && actualEdge !== undefined)) && <div className="section">
            <div className="valueSpinnerTextHighlightPlaceholder title">
                <ValueSpinner isLoading={isLoading}>
                    {label}
                </ValueSpinner>
            </div>

            <LocalizationInfoStats />
            <StatsSubSection title="common.transitionDeviation" spotlightId={buildKpiSpotlightId(KpiTypes.QueuingTime, ["Edge", "Deviation"])}>
                <StatsRowValueSigned label="common.mean" value={deviationEdge?.timeStatistics?.mean} isHighlight={isHighlightedStats(StatisticTypes.Mean, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
                <StatsRowValueSigned label="common.statistics.median" value={deviationEdge?.timeStatistics?.median} isHighlight={isHighlightedStats(StatisticTypes.Median, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
                <StatsRowValueSigned label="common.statistics.sum" value={deviationEdge?.timeStatistics?.sum} isHighlight={isHighlightedStats(StatisticTypes.Sum, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
            </StatsSubSection>
            <StatsSubSection title="common.statistics.freqEdge" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge", "Deviation"])}>
                <StatsRowValueSigned label="common.difference" value={deviationEdge?.frequencyStatistics?.sum} isHighlight={isHighlighted(KpiTypes.Frequency, settings)} isLoading={isLoading} />
            </StatsSubSection>

        </div>}

        {!isLoading && edgeUnplannedExecution && <div className="section">
            <div className="title">{`${i18n.t("common.scheduleDeviation")} ${label}`}</div>
            {/* Dropdown for object centric */}
            <LocalizationInfoStats />
            <StatsSubSection title="common.transitionDeviation" spotlightId={buildKpiSpotlightId(KpiTypes.QueuingTime, ["Edge", "Deviation"])}>
                <StatsRowValueSigned label="common.mean" value={actualEdge?.timeStatistics?.mean} isHighlight={isHighlightedStats(StatisticTypes.Mean, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
                <StatsRowValueSigned label="common.statistics.median" value={actualEdge?.timeStatistics?.median} isHighlight={isHighlightedStats(StatisticTypes.Median, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
                <StatsRowValueSigned label="common.statistics.sum" value={actualEdge?.timeStatistics?.sum} isHighlight={isHighlightedStats(StatisticTypes.Sum, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
            </StatsSubSection>
            <StatsSubSection title="common.statistics.freqEdge" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge", "Deviation"])}>
                <StatsRowValueSigned label="common.difference" value={actualEdge?.frequencyStatistics?.sum} isHighlight={isHighlighted(KpiTypes.Frequency, settings)} isLoading={isLoading} />
            </StatsSubSection>
            <div className="explanation">{i18n.t("workflows.planningDeviation.unplannedEdgeExplanation")}</div>
        </div>}

        {!isLoading && edgePlanWithoutExecution && <div className="section">
            <div className="title">{`${i18n.t("common.scheduleDeviation")} ${label}`}</div>
            <LocalizationInfoStats />
            <StatsSubSection title="common.transitionDeviation" spotlightId={buildKpiSpotlightId(KpiTypes.QueuingTime, ["Edge", "Deviation"])}>
                <StatsRowValueSigned label="common.mean" value={plannedEdge?.timeStatistics?.mean ? plannedEdge?.timeStatistics?.mean * (-1) : undefined} isHighlight={isHighlightedStats(StatisticTypes.Mean, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
                <StatsRowValueSigned label="common.statistics.median" value={plannedEdge?.timeStatistics?.median ? plannedEdge?.timeStatistics?.median * (-1) : undefined} isHighlight={isHighlightedStats(StatisticTypes.Median, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
                <StatsRowValueSigned label="common.statistics.sum" value={plannedEdge?.timeStatistics?.sum ? plannedEdge?.timeStatistics?.sum * (-1) : undefined} isHighlight={isHighlightedStats(StatisticTypes.Sum, settings)} isLoading={isLoading} unit={Formatter.units.durationShort} />
            </StatsSubSection>
            <StatsSubSection title="common.statistics.freqEdge" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge", "Deviation"])}>
                <StatsRowValueSigned label="common.difference" value={plannedEdge?.frequencyStatistics?.sum ? plannedEdge?.frequencyStatistics?.sum * (-1) : undefined} isHighlight={isHighlighted(KpiTypes.Frequency, settings)} isLoading={isLoading} />
            </StatsSubSection>
            <div className="explanation">{i18n.t("workflows.planningDeviation.planNotExecutedExplanationEdge")}</div>
        </div>}
    </div>;

    function renderNodeStatistics(node: Node | undefined) {
        const defaultOptions = { isLoading: isLoading, addFreq: true, disableMinMax: true, addSign: true };
        if (node === undefined)
            return;
        return <>
            {!groupingKeysNoneGroups.includes(settings.groupingKey) &&
                !(node?.frequencyStatistics?.sum === undefined && settings.kpi.selectedKpi === KpiTypes.ThroughputTime) &&
                !(settings.kpi.selectedKpi === KpiTypes.SetupTime && session.project?.eventKeys?.isSetup === undefined && session.project?.eventKeysPlan?.isSetup === undefined) &&
                settings.kpi.selectedKpi !== KpiTypes.Frequency &&
                <NodeStats title={`${i18n.t("common.scheduleDeviation")} ${session.locale === "de" ? i18n.t(`${kpiDefinition?.label}`) : i18n.t(`${kpiDefinition?.label}`).toLocaleLowerCase()}`} kpiType={settings.kpi.selectedKpi} spotlightSuffixes={["Deviation"]} node={node} {...defaultOptions} disableMedian={apiMode === ApiModes.Routings} />}
        </>;
    }
}

function getObjectType(settings: SettingsType) {

    const objectTypes = settings.selection.node !== undefined ? getNodeObjectTypes(settings.selection.node) : getEdgeObjectTypes(settings.selection.edge);

    if (settings.graph.objectType && objectTypes.includes(settings.graph.objectType))
        return settings.graph.objectType;
    return objectTypes[0];
}





