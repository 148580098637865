import { ChartEdge } from "../components/dfg/ChartEdge";
import { FreqStats, Stats } from "./Stats";

export type NodeKpiSchema = {
    carbonMassPerYieldMass?: number;
    carbonMassPerYieldLength?: number;
    carbonMassPerYieldCount?: number;

    electricityEnergyPerYieldMass?: number;
    electricityEnergyPerYieldLength?: number;
    electricityEnergyPerYieldCount?: number;

    gasEnergyPerYieldMass?: number;
    gasEnergyPerYieldLength?: number;
    gasEnergyPerYieldCount?: number;

    yieldRateMass?: number;
    yieldRateLength?: number;
    yieldRateCount?: number;

    outputRateMass?: number;
    outputRateLength?: number;
    outputRateCount?: number;

    scrapRateMass?: number;
    scrapRateLength?: number;
    scrapRateCount?: number;

    relativeYieldMass?: number;
    relativeYieldLength?: number;
    relativeYieldCount?: number;

    relativeScrapMass?: number;
    relativeScrapLength?: number;
    relativeScrapCount?: number;

    availability?: number;

    bottleneckFactorOutputRateMass?: number;
    bottleneckFactorOutputRateLength?: number;
    bottleneckFactorOutputRateCount?: number;

    cycleTimeMass?: number;
    cycleTimeLength?: number;
    cycleTimeCount?: number;

    [key: string]: number | undefined;
};

export type EdgeKpiStats = {
    yieldRateMass?: number;
    yieldRateLength?: number;
    yieldRateCount?: number;
    averageYieldStockMass?: number;
    averageYieldStockLength?: number;
    averageYieldStockCount?: number;

    [key: string]: number | undefined;
}

export type EdgeRenderOptions = {
    points?: Point[];
    labelLocation?: {
        x: number;
        y: number;
    }
};

type BasicEdge = {
    from: string;
    to: string;
    renderOptions?: EdgeRenderOptions
};

export type EdgeStats =  {
    frequencyStatistics?: Stats;
    timeStatistics?: Stats;
    netEdgeTimeStatistics?: Stats;

    yieldMassStatistics?: Stats;
    yieldCountStatistics?: Stats;
    yieldLengthStatistics?: Stats;

    /**
     * The higher the score, the more important this edge is for the graph
     */

    score?: number;

    kpis?: EdgeKpiStats;
    customKpis?: CustomKpiSchema;
};

export type BomMetaData = {
    componentRank: NodeActivityValueSchema;
    componentPosition: NodeActivityValueSchema;
}

export type  BomEdge =  {
    componentMassStatistics?: Stats;
    componentLengthStatistics?: Stats;
    componentCountStatistics?: Stats;
    count?: number;
    metadata?: BomMetaData;
    role?: NodeRoles;
}

export type BomNode = {
    componentMassStatistics?: Stats;
    componentLengthStatistics?: Stats;
    componentCountStatistics?: Stats;
    metadata?: BomMetaData;
    name?: string;
    role?: NodeRoles;
    hasIncomingEdges?: boolean;
}

export type Edge = BasicEdge & EdgeStats & BomEdge & { 
    objectType?: string,
};

export type MultiEdge = BasicEdge & {
    edges: Edge[]
};

export type NodeMeta = { [key: string]: number | string | undefined };

/**
 * Reflects the columns you can assign in the IdentifyColumns view. Make sure
 * assignable columns match the properties defined here.
 */
export type NodeMetaTyped = {
    id?: string | number;
    operation?: string | number;
    startTime?: string;
    endTime?: string;
    machine?: string | number;
    machineType?: string | number;
    passId?: string | number;
    location?: string | number;
    product?: string | number;
    isFailure?: boolean | number;
    isSetup?: boolean | number;
    isInterruption?: boolean | number;
    isPassChange?: boolean | number;
    isLink?: boolean | number;
    role?: string;
    yield?: number;
    scrap?: number;
};

export type NodeActivityValueSchema = {
    value?: string;
    nUnique: number;
}


export type NodeActivitySchema = {
    location?: NodeActivityValueSchema,
    machine?: NodeActivityValueSchema,
    machineType?: NodeActivityValueSchema,
    operation: NodeActivityValueSchema,
    passId?: NodeActivityValueSchema,
    timeComponent?: NodeActivityValueSchema,
    objectType?: NodeActivityValueSchema,
    product?: NodeActivityValueSchema,
}

type RenderPositionOptions = {
    position: {
        x: number
        y: number
    },
    size: {
        width: number
        height: number
    }
}

export type RenderOptions = Partial<RenderPositionOptions> & {
    color?: string
    className?: string
};

export type RenderNodeGroupOptions = RenderPositionOptions & { label?: string }
export type RenderNodeOptions = RenderPositionOptions;

export type Layout = {
    nodes: { [id: string]: RenderPositionOptions };
    groups: { [id: string]: RenderNodeGroupOptions };
    edges: ChartEdge[];

    // Hash to identify this layout. Use it as a stable reference in your useEffect or useMemo calls
    hash: string;
}

export type NodeObject = NodeStats & {
    type: string | undefined;
}

export type NodeStats = {
    timeStatistics?: Stats;
    frequencyStatistics?: FreqStats;
    activityPassTimeStatistics?: Stats;
    activityPassFrequencyStatistics?: FreqStats;
    passTimeStatistics?: Stats;
    pauseTimeStatistics?: Stats;
    setupTimeStatistics?: Stats;
    setupFrequencyStatistics?: FreqStats;
    failureTimeStatistics?: Stats;
    failureFrequencyStatistics?: FreqStats;
    interruptionTimeStatistics?: Stats;
    interruptionFrequencyStatistics?: FreqStats;
    productionTimeStatistics?: Stats;
    productionFrequencyStatistics?: FreqStats;
    pureObjectTimeStatistics?: Stats;
    pureObjectFrequencyStatistics?: FreqStats;
    passChangeTimeStatistics?: Stats;
    passChangeFrequencyStatistics?: FreqStats;
    busyTimeStatistics?: Stats;
    busyFrequencyStatistics?: FreqStats;
    unknownTimeStatistics?: Stats;
    unknownFrequencyStatistics?: FreqStats;
    outputMassStatistics?: Stats;
    outputLengthStatistics?: Stats;
    outputCountStatistics?: Stats;
    yieldMassStatistics?: Stats;
    yieldLengthStatistics?: Stats;
    yieldCountStatistics?: Stats;
    scrapMassStatistics?: Stats;
    scrapLengthStatistics?: Stats;
    scrapCountStatistics?: Stats;

    electricityEnergyStatistics?: Stats;
    gasEnergyStatistics?: Stats;
    carbonMassStatistics?: Stats;

    kpis?: NodeKpiSchema;
    customKpis?: CustomKpiSchema;

    // stat that is pulled from another endpoint and supplied by the default api
    throughputTime?: number;
}

export type Node = NodeStats & BomNode & {
    id: string;
    name?: string;
    activityValues?: NodeActivitySchema;
    role?: NodeRoles;

    /**
     * The higher the score, the more important this node is for the graph
     */
    score?: number;

    objects?: NodeObject[];

    /**
     * infos on start edges. not actually used yet, but saved here because we might remove them using
     * DfgUtils.filterPureObjectGraph
     */
    startEdges?: Edge[];

    /**
     * infos on end edges. not actually used yet, but saved here because we might remove them using
     * DfgUtils.filterPureObjectGraph
     */
    endEdges?: Edge[];

    meta?: NodeMeta;
    metaTyped?: NodeMetaTyped;

    [key: string]: Stats | CustomKpiSchema | NodeKpiSchema | NodeObject[] | number | string | boolean | NodeRoles | NodeActivitySchema | BomMetaData | NodeMetaTyped | RenderOptions | Edge[] | undefined;
}

export type LogTimesSchema = {
    start: string;
    end: string;
    duration: number;
}

type CustomKpiSchema = {
   [id: string]: {
        value?: number | undefined,
    };
}

export type Log = {
    frequencyStatistics?: Stats;
    timeStatistics?: Stats;
    eventTimeStatistics?: Stats;
    eventFrequencyStatistics?: Stats;
    edgeTimeStatistics?: Stats;
    edgeFrequencyStatistics?: Stats;
    scrapMassStatistics?: Stats;
    scrapCountStatistics?: Stats;
    scrapLengthStatistics?: Stats;
    outputMassStatistics?: Stats;
    outputCountStatistics?: Stats;
    outputLengthStatistics?: Stats;
    yieldMassStatistics?: Stats;
    yieldCountStatistics?: Stats;
    yieldLengthStatistics?: Stats;

    [key: string]: Stats | NodeKpiSchema | undefined;
}

export enum NodeDetailLevel {
    Simple = 0,
    Detailed = 1
}

export enum GroupingKeys {
    None = "none",
    Machine = "machine",
    MachineType = "machineType",
    Location = "location",
    /**
    * Refers to the none grouping and with passIds separated from each other.
    * Concerns especially dfg, node and edge views.
    */
    NoneValueStream = "noneValueStream",
    /**
    * Refers to the machine (work place) grouping and with passIds separated from each other.
    * Concerns especially dfg, node and edge views.
    */
    MachineValueStream = "machineValueStream",
    /**
    * Refers to the machineType grouping and with passIds separated from each other.
    * Concerns especially dfg, node and edge views.
    */
    MachineTypeValueStream = "machineTypeValueStream",
    /**
    * Refers to the location grouping and with passIds separated from each other.
    * Concerns especially dfg, node and edge views.
    */
    LocationValueStream = "locationValueStream",
    /**
    * Refers to a grouping based on passIds separated from each other.
    * Concerns especially dfg, node and edge views.
    */
    PassValueStream = "passValueStream",
    /**
    * Refers to a grouping based on object types.
    * Concerns especially dfg, node and edge views.
     */
    ObjectType = "objectType",
    /**
    * Refers to a grouping based on object types and machines (work places). Machines are separated by object type.
    * This may lead to multiple machines with the same name handling different object types.
    * Concerns especially dfg, node and edge views.
     */
    MachineObjectType = "machineObjectType",
    /**
    * Refers to a grouping based on object types and with passIds separated from each other.
    * Concerns especially dfg, node and edge views.
     */
    ObjectTypeValueStream = "objectTypeValueStream",
    /**
    * Refers to a grouping based on object types, machines (work places) and passIds separated. Machines are separated by object type.
    * This may lead to multiple machines with the same name handling different object types.
    * Concerns especially dfg, node and edge views.
     */
    MachineObjectTypeValueStream = "machineObjectTypeValueStream",

    /**
     * Refers to a grouping based on product types. This is only used as secondary grouping option thus far.
     */
    Product = "product",
}

export const groupingKeysPassCompatibleGroups = [GroupingKeys.MachineValueStream, GroupingKeys.Machine, GroupingKeys.Location, GroupingKeys.LocationValueStream, GroupingKeys.MachineType, GroupingKeys.MachineTypeValueStream, GroupingKeys.PassValueStream, GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream];
export const groupingKeysNoneGroups = [GroupingKeys.None, GroupingKeys.NoneValueStream];
export const groupingKeysObjects = [GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream];

export enum NodeRoles {
    Start = "START",
    End = "END",
    Group = "GROUP",
    CarbonScope3 = "CARBONSCOPE3",
    Link = "LINK",
    Inventory = "INVENTORY",
    Machine = "MACHINE",
    BillOfMaterials = "BILL_OF_MATERIALS",
}

export type BaseGraph = {
    edges: Edge[];
    multiEdges?: MultiEdge[];
    nodes: Node[];
    log?: Log;

    /**
     * Hash of this graph (excluding the hash itself).
     * If JSON.stringify(graphA) === JSON.stringify(graphB) then also graphA.hash === graphB.hash.
     * Use this in your useEffect or useMemo hooks!
     */
    hash: string;
}

export type ApiGraph = BaseGraph &{
    planned?: BaseGraph;
    deviation?: BaseGraph & {
        relativeToPlanned?: BaseGraph;
    }
}

export type Graph = ApiGraph & {
    multiEdges: MultiEdge[];
}

export type ApiDeviationGraphs = {
    actual?: ApiGraph;
    planned?: ApiGraph;
    deviation?: ApiGraph;
}

export type DeviationGraphResponse = {
    actual?: BaseGraph;
    planned?: BaseGraph;
    deviation?: BaseGraph;
}

export type Dfg = {
    id: string;
    userId: string;
    created: Date;
    updated?: Date;
    uploadId: string;
    jobId: string;
    pending: boolean;
    graph: Graph;
}

export type Point = {
    x: number;
    y: number;
}

export const CASE_TYPE_ID = "CASE";
export const START_NODE_ID_INDICATOR = "_START_PLACEHOLDER__";
export const END_NODE_ID_INDICATOR = "_END_PLACEHOLDER__";
export const START_NODE_ID = `__${CASE_TYPE_ID}${START_NODE_ID_INDICATOR}`;
export const END_NODE_ID = `__${CASE_TYPE_ID}${END_NODE_ID_INDICATOR}`;
export const ALL_OBJECT_INDICATOR = "__ALL_OBJECTS__";
