import { useContext, useEffect, useState } from "react";
import { disableAllCalcOptions, DfgRequest, StatsCalculationRequest } from "../models/ApiTypes";
import { SessionContext, hasNetTransitionTimes } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { Graph, GroupingKeys } from "../models/Dfg";
import { EventKeys } from "../models/EventKeys";
import { addAverageStockToGraphNodes } from "../utils/AverageStock";
import { Datastores } from "../utils/Datastores";
import { DfgUtils, getGraphHash } from "../utils/DfgUtils";
import { addEnergyStatsToGraph } from "../utils/FakeEnergyData";
import { getIsGroupingValid } from "../utils/Initializers";
import { useMountedState } from "./UseMounted";
import { removeStatsAfterLinks } from "../utils/ObjectGroupingDfg";
import { noop } from "lodash";

export enum AnalysisType {
    Output = "output",
    Stock = "stock",
    Times = "times",
    Energy = "energy",
    Variants = "variants",
    ValueStream = "valueStream"
}

export const calculateOutputStatsArguments: Partial<StatsCalculationRequest> = {
    calculateOutputStats: true,
    calculateTimeAndFreqStats: true,
    calculateKpis: true,
    useActivityPasses: true,
};

export const calculateValueStreamStatsArguments: Partial<StatsCalculationRequest> = {
    calculateSetupStats: true,
    calculateOutputStats: true,
    useActivityPasses: true
};

/**
 * These options are needed if you want to layout and render the graph
 */
export const calculateGraphDisplayArguments: Partial<StatsCalculationRequest> = {
    calculateScores: true,
    calculateRoles: true,
    calculateNodes: true,
    calculateEdges: true,
    calculateActivityValues: true,
};

export const analysisGraphMapping: { analysisType: AnalysisType, arguments: StatsCalculationRequest }[] = [
    {
        analysisType: AnalysisType.Output,
        arguments: calculateOutputStatsArguments,
    },
    {
        analysisType: AnalysisType.Stock,
        arguments: {
            ...calculateOutputStatsArguments,
            calculateEdges: true,
        }
    },
    {
        analysisType: AnalysisType.Energy,
        arguments: calculateOutputStatsArguments,
    },
    {
        analysisType: AnalysisType.Times,
        arguments: {
            calculateUnknownStats: true,
            calculateNetEdgeTimes: true,
            useActivityPasses: true,
        }
    },
    {
        analysisType: AnalysisType.Variants,
        arguments: {
            calculateUnknownStats: true,
            useActivityPasses: true,
        }
    },
    {
        analysisType: AnalysisType.ValueStream,
        arguments: {
            ...calculateValueStreamStatsArguments,
            calculateKpis: true,
            calculateBusyStats: true,
            calculateInterruptionStats: true,
            calculateFailureStats: true,
        }
    },
];

export type UseGraphArguments = Partial<DfgRequest & {
    groupingKey: GroupingKeys,
}>;

export function useGraph(requestOptions?: UseGraphArguments, analysis: AnalysisType = AnalysisType.Times, updateSelection = true, disable = false) {
    const isMounted = useMountedState();
    const sessionContext = useContext(SessionContext);
    const settingsContext = useContext(SettingsContext);

    const [graph, setGraph] = useState<Graph | undefined>();

    const filters = requestOptions?.eventFilters ?? settingsContext.previewFilters ?? settingsContext.filters;

    const eventKeys = requestOptions?.eventKeys ?? sessionContext.project?.eventKeys;
    const uploadId = requestOptions?.uploadId ?? sessionContext.project?.uploadId;
    const groupingKey = requestOptions?.groupingKey ?? settingsContext.groupingKey;


    const options: DfgRequest = {
        ...disableAllCalcOptions,
        ...requestOptions,
        // The API returns an error if this is set to true but there's no shift plan
        calculateNetEdgeTimes: (requestOptions?.calculateNetEdgeTimes ?? false) && hasNetTransitionTimes(sessionContext),
        eventFilters: filters,
        eventKeys: {
            ...eventKeys,
            activityKeysGroup: groupingKey,
        } as EventKeys,
        uploadId: uploadId!,
        uploads: sessionContext.project?.uploads,
    };


    const [subscriptionId] = useState(() => { 
        return Datastores.dfg.getSubscriptionId(); 
    });
    useEffect(() => {
        return () => {
            Datastores.dfg.cancelSubscription(subscriptionId);
        };
    }, []);

    useEffect(() => {
        if (disable || !eventKeys || !uploadId || (requestOptions?.eventKeys === undefined && requestOptions?.groupingKey === undefined && !getIsGroupingValid(sessionContext.project?.eventKeys, settingsContext.groupingKey))) {
            setGraph(undefined);
            return;
        }

        setGraph(undefined);

        // Cancel already issued requests
        Datastores.dfg.cancelSubscription(subscriptionId);

        Datastores.dfg.get(options, subscriptionId).then((response) => {
            if (!isMounted())
                return;

            let dfg = DfgUtils.preprocessGraph(response);

            if (analysis === AnalysisType.Energy && !sessionContext.project?.eventKeys?.carbonMass && !sessionContext.project?.eventKeys?.electricityEnergy) {
                dfg = addEnergyStatsToGraph(dfg);
                dfg = DfgUtils.mergeGraphEdges(dfg);
            }
            if ([AnalysisType.Stock, AnalysisType.Output, AnalysisType.ValueStream].includes(analysis))
                dfg = addAverageStockToGraphNodes(dfg);

            if ([GroupingKeys.ObjectType, GroupingKeys.ObjectTypeValueStream, GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream].includes(groupingKey)) {
                dfg = removeStatsAfterLinks(dfg);
                dfg = DfgUtils.mergeGraphEdges(dfg);
            }

            setGraph({
                ...dfg as Graph,
                hash: getGraphHash(dfg),
            });

            if (updateSelection) {
                // If a node or edge is selected, translate it to the corresponding
                // instance that we received here. The metadata might have
                // changed!
                const selectedNode = settingsContext.selection.node === undefined ? undefined :
                    dfg.nodes.find(node => node.id === settingsContext.selection.node!.id);

                const selectedEdge = settingsContext.selection.edge === undefined ? undefined :
                    dfg.multiEdges!.find(multiEdge =>
                        multiEdge.from === settingsContext.selection.edge!.from &&
                        multiEdge.to === settingsContext.selection.edge!.to);

                if (selectedNode !== settingsContext.selection.node ||
                    selectedEdge !== settingsContext.selection.edge)
                    settingsContext.setSelection({
                        node: selectedNode,
                        edge: selectedEdge,
                    });
            }
        }).catch(noop);
    }, [
        sessionContext.project,
        disable,
        analysis,
        settingsContext.apiRetry,
        settingsContext.quantity,
        JSON.stringify(requestOptions),
        JSON.stringify(options),
    ]);
    return graph;
}
