import React, { Fragment, useContext } from "react";
import { CaseAggregationStatistics } from "../../../models/Case";
import { KpiDefinition, buildKpiSpotlightId, getKpiDefinition, getKpiSpotlightId, getStatisticFromObject, getUnit } from "../../../models/Kpi";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { KpiComparisons } from "../../../contexts/ContextTypes";
import i18n, { getFirstExistingSpotlight } from "../../../i18n";
import { Formatter, UnitMetadata } from "../../../utils/Formatter";
import { StatsRowValue } from "../../../components/stats-section/StatsRowValue";
import { StatsRowPercentBar } from "../../../components/stats-section/StatsRowPercentBar";
import { Stats } from "../../../models/Stats";
import VarianceStatistics from "../../../components/variance-statistics/VarianceStatistics";
import { StatsSection, StatsSubSection } from "../../../components/stats-section/StatsSection";
import { TimeperiodCaseAggregationStatisticsSchema } from "../../../models/ApiTypes";
import { KpiTypes, StatisticTypes } from "../../../models/KpiTypes";
import { buildSpotlightId } from "../../../utils/Utils";
import { useLocation } from "react-router-dom";
import { isHighlightedStats } from "../../../components/stats-section/Utility";

type AggregatedCaseStatsProps = {
    title: string;
    kpiDefinitions: KpiDefinition[];
    selection?: CaseAggregationStatistics | TimeperiodCaseAggregationStatisticsSchema;
    selectionPlan?: CaseAggregationStatistics | TimeperiodCaseAggregationStatisticsSchema;
    count?: number;
    countLabel?: string;
    isLoading: boolean;
    hasBusyTimeSubStats?: boolean;
    useLogPath?: boolean;
}

/**
 * This is used for rendering data fetched by TimeperiodStats and ProductStats
 */
export default function AggregatedCaseStats(props: AggregatedCaseStatsProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const location = useLocation();

    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning;

    const isTimeKpi = [KpiTypes.BusyTime, KpiTypes.InterruptionTime, KpiTypes.FailureTime, KpiTypes.SetupTime, KpiTypes.ProductionTime, KpiTypes.ScrapQuantity,
        KpiTypes.OrganizationalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.TechnicalLosses, KpiTypes.WorkInProcessInventory].includes(settings.kpi.selectedKpi);

    const kpiSections = props.kpiDefinitions.map((kpiDefinition, index) => {
        if (kpiDefinition === undefined)
            return null;
        const variance = getStatisticFromObject(props.selection, kpiDefinition, StatisticTypes.Variance, settings.kpi.aggregation, props.useLogPath) as Stats;
        const mean = getStatisticFromObject(props.selection, kpiDefinition, StatisticTypes.Mean, settings.kpi.aggregation, props.useLogPath) as number;
        const sum = getStatisticFromObject(props.selection, kpiDefinition, StatisticTypes.Sum, settings.kpi.aggregation, props.useLogPath) as number;

        const variancePlan = getStatisticFromObject(props.selectionPlan, kpiDefinition, StatisticTypes.Variance, settings.kpi.aggregation, props.useLogPath) as Stats;
        const meanPlan = getStatisticFromObject(props.selectionPlan, kpiDefinition, StatisticTypes.Mean, settings.kpi.aggregation, props.useLogPath) as number;
        const sumPlan = getStatisticFromObject(props.selectionPlan, kpiDefinition, StatisticTypes.Sum, settings.kpi.aggregation, props.useLogPath) as number;

        const isEmpty = variance === undefined && mean === undefined && sum === undefined;
        const isPlanEmpty = variancePlan === undefined && meanPlan === undefined && sumPlan === undefined;

        if (isEmpty && isPlanEmpty)
            return null;

        if (!kpiDefinition.allowedStatistics.includes(StatisticTypes.Variance))
            return <Fragment key={`stats-${index}`}>
                {!isEmpty && <StatsTable title={kpiDefinition?.label} isSelectedKpi={settings.kpi.selectedKpi === kpiDefinition?.id} variance={variance} mean={mean} sum={sum} count={props.count} unit={kpiDefinition?.unit} isLoading={props.isLoading} spotlightId={buildKpiSpotlightId(kpiDefinition.id)} />}

                {isPlanningComparison && !isPlanEmpty && <StatsTable isSelectedKpi={settings.kpi.selectedKpi === kpiDefinition?.id} title={kpiDefinition?.labelPlan} variance={variancePlan} mean={meanPlan} sum={sumPlan} count={props.count} unit={kpiDefinition?.unit} isLoading={props.isLoading} spotlightId={buildKpiSpotlightId(kpiDefinition.id)} />}
            </Fragment>;

        return <Fragment key={`stats-variance-${index}`}>
            <StatsSubSection title={kpiDefinition?.label} disableTable={true} spotlightId={getKpiSpotlightId(session, kpiDefinition.id, settings.kpi.aggregation)}>
                <VarianceStatistics
                    variance={variance}
                    mean={mean}
                    sum={sum}
                    isLoading={props.isLoading}
                    unit={kpiDefinition?.unit}
                    isSelectedKpi={settings.kpi.selectedKpi === kpiDefinition?.id}
                    count={props.count}
                />
            </StatsSubSection>
            {isPlanningComparison && !isPlanEmpty && <StatsTable title={kpiDefinition?.labelPlan} isSelectedKpi={settings.kpi.selectedKpi === kpiDefinition?.id} variance={variancePlan} mean={meanPlan} sum={sumPlan} count={props.count} unit={kpiDefinition?.unit} isLoading={props.isLoading} spotlightId={getKpiSpotlightId(session, kpiDefinition.id, settings.kpi.aggregation)} />}
        </Fragment>;
    });

    return <div data-testid="sidePanel">
        <StatsSection title={props.title} isTitleLoading={props.isLoading}>
            {kpiSections}

            {/* Show the components of the busy times only if this is really intended (hasBusyTimeSubStats) */}
            {props.hasBusyTimeSubStats && <StatsSubSection title="common.busyTime" spotlightId={getKpiSpotlightId(session, KpiTypes.BusyTime, settings.kpi.aggregation)}>
                <StatsRowValue isLoading={props.isLoading} label="common.statistics.total" unit={Formatter.units.durationShort} value={(props.selection?.caseBusyTimeStatistics as Stats)?.sum} />

                <BusyProductBar label="common.production" time={props.selection?.caseProductionTimeStatistics?.sum} busyTime={props.selection?.caseBusyTimeStatistics?.sum} isLoading={props.isLoading} locale={session.numberFormatLocale} />
                <BusyProductBar label="common.setup" time={props.selection?.caseSetupTimeStatistics?.sum} busyTime={props.selection?.caseBusyTimeStatistics?.sum} isLoading={props.isLoading} locale={session.numberFormatLocale} />
                <BusyProductBar label="common.failure" time={props.selection?.caseFailureTimeStatistics?.sum} busyTime={props.selection?.caseBusyTimeStatistics?.sum} isLoading={props.isLoading} locale={session.numberFormatLocale} />
                <BusyProductBar label="common.interruption" time={props.selection?.caseInterruptionTimeStatistics?.sum} busyTime={props.selection?.caseBusyTimeStatistics?.sum} isLoading={props.isLoading} locale={session.numberFormatLocale} />
            </StatsSubSection>}

            {props.isLoading || props.count !== undefined && !(isTimeKpi && settings.selection.timeperiod !== undefined) && <StatsSubSection title={props.countLabel ?? getKpiDefinition(KpiTypes.OrderCount, { settings, session })?.label}
                spotlightId={getFirstExistingSpotlight(session, [
                    buildSpotlightId(location.pathname, ["Statistics", "OrderCount"]),
                    getKpiSpotlightId(session, KpiTypes.OrderCount, settings.kpi.aggregation)
                ])}
            >
                <StatsRowValue isLoading={props.isLoading} label="common.statistics.sum" unit={Formatter.units.numberShort} value={props.count} isHighlight={isHighlightedStats(StatisticTypes.Sum, settings)} />
            </StatsSubSection>}

            {!props.isLoading && props.count === undefined && <div className="explanation">
                {i18n.t("common.noCasesForSelectedProduct")}
            </div>}
        </StatsSection>
    </div>;
}

function StatsTable(props: { title?: string, variance?: Stats | undefined, mean?: number, sum?: number, count?: number, unit?: UnitMetadata | { sum: UnitMetadata, mean: UnitMetadata }, isLoading?: boolean, spotlightId?: string, isSelectedKpi?: boolean }) {
    const settings = useContext(SettingsContext);
    const unitMean = getUnit(props.unit, StatisticTypes.Mean);
    const unitSum = getUnit(props.unit, StatisticTypes.Sum);
    const isSingleValue = props.count === 1;
    const hasNoVariance = isSingleValue || props.variance?.min === props.variance?.max;

    let singleValue: number | undefined = undefined;
    let singleValueUnit: UnitMetadata | undefined = undefined;
    if (isSingleValue) {
        if (props.mean !== undefined) {
            singleValue = props.mean;
            singleValueUnit = unitMean;
        }
        else if (props.sum !== undefined) {
            singleValue = props.sum;
            singleValueUnit = unitSum;
        }
    }

    if (isSingleValue && singleValue !== undefined && singleValueUnit !== undefined)
        // Single value, no statistics needed
        return <StatsSubSection title={props.title} spotlightId={props.spotlightId}>
            <StatsRowValue isLoading={props.isLoading} label="common.value" unit={singleValueUnit} value={singleValue} isHighlight={props.isSelectedKpi} />
        </StatsSubSection>;

    return <StatsSubSection title={props.title} spotlightId={props.spotlightId}>
        {props.mean !== undefined && isFinite(props.mean) && <StatsRowValue isLoading={props.isLoading} label="common.statistics.mean" unit={unitMean} value={props.mean} isHighlight={isHighlightedStats(StatisticTypes.Mean, settings) && props.isSelectedKpi} />}
        <StatsRowValue isLoading={props.isLoading} label="common.statistics.median" unit={unitMean} value={props.variance?.median} isHighlight={[StatisticTypes.Median, StatisticTypes.Variance].includes(settings.kpi.statistic) && props.isSelectedKpi} />
        {!hasNoVariance && <StatsRowValue isLoading={props.isLoading} label="common.statistics.min" unit={unitMean} value={props.variance?.min} />}
        {!hasNoVariance && <StatsRowValue isLoading={props.isLoading} label="common.statistics.max" unit={unitMean} value={props.variance?.max} />}
        {props.sum  !== undefined && isFinite(props.sum) && <StatsRowValue isLoading={props.isLoading} label="common.statistics.sum" unit={unitSum} value={props.sum} isHighlight={isHighlightedStats(StatisticTypes.Sum, settings) && props.isSelectedKpi} />}
    </StatsSubSection>;
}

function BusyProductBar(props: { label: string, time: number | undefined, busyTime: number | undefined, isLoading: boolean, locale: string }) {

    if ((props.time === undefined || props.busyTime === undefined) && !props.isLoading)
        return <></>;

    const value = (!props.isLoading && props.time !== undefined && props.busyTime !== undefined) ? props.time / props.busyTime : undefined;

    const barLabel = Formatter.formatPercent(value, 1, 0, props.locale);

    return <StatsRowPercentBar isLoading={props.isLoading} value={value} of={1} label={props.label} barLabel={barLabel} />;

}
