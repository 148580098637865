import React, { useContext } from "react";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { GroupingKeyControls, VisibilityOptions, getValidGroupingKeyControlSettings } from "./GroupingKeyControls";
import { KpiControls, getValidKpiControlSettings } from "./KpiControls";
import { ObjectControls, getValidObjectControlsSettings } from "./ObjectControls";
import { KpiComparisonControls } from "./KpiComparisonControls";
import { getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import { GroupingKeys } from "../../models/Dfg";
import i18n from "../../i18n";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { SessionType } from "../../contexts/SessionContext";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { classNames } from "../../utils/Utils";


export function getValidTaktTimeControlSettings(session: SessionType, settings: SettingsType) {
    return ObjectMerger.mergeObjects([
        getValidKpiControlSettings(session, settings, {
            kpis: [KpiTypes.CycleTime],
            hasYAxis: true,
            addQuantityDropdown: true,
            hideStatistics: [StatisticTypes.Sum],
        }),
        getValidObjectControlsSettings(session, settings),
        getValidGroupingKeyControlSettings(session, settings, { options: { none: VisibilityOptions.Hidden, location: VisibilityOptions.Hidden, machineType: VisibilityOptions.Hidden }, secondaryGrouping: VisibilityOptions.Hidden, isXAxisLabel: true }),
    ]);
}

export function TaktTimeControls() {
    const settings = useContext(SettingsContext);
    
    return <div className="controls">

        <KpiControls
            kpis={[KpiTypes.CycleTime]}
            hasYAxis={true}
            addQuantityDropdown={true}
            hideStatistics={[StatisticTypes.Sum]}
            subcontrols={<TaktSwitch />}
        />

        <ObjectControls showContextSwitch={false} />

        <GroupingKeyControls
            options={{ none: VisibilityOptions.Hidden, location: VisibilityOptions.Hidden, machineType: VisibilityOptions.Hidden }}
            secondaryGrouping={VisibilityOptions.Hidden}
            isXAxisLabel={true}
            disableOrderSequenceCheckbox={true}
        />

        {settings.kpi.statistic !== StatisticTypes.Variance && <KpiComparisonControls
            enabledComparisons={getEnabledComparisons}
            showHighlightControls={true} />}

    </div>;
}

function getEnabledComparisons(session: SessionType, settings: SettingsType) {
    // The best case comparison only makes sense for the machine groupings 
    // because the statistics for cycle times are not comparable when multiple machines are involved.
    if ([GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey))
        return getEnabledComparisonsValueStream(session, settings, true);
    return getEnabledComparisonsValueStream(session, settings);
}

/**
 * Checkbox component to switch the takt time on and off.
 */
export function TaktSwitch(props: { isDisabled?: boolean }) {
    const settings = useContext(SettingsContext);

    // The takt time is only available for the PassValueStream grouping.
    // For all other groupings the checkbox is disabled and not checked.
    const isDisabled = props.isDisabled || settings.groupingKey !== GroupingKeys.PassValueStream;
    const checked = settings.groupingKey === GroupingKeys.PassValueStream ? settings.kpi.showCustomerTakt : false;

    return <div className={classNames(["customerTaktSwitch", "mtLarge", isDisabled && "disabled"])}>
        <label>
            <input type="checkbox" id="checkbox-customerTakt" disabled={isDisabled} className="checkbox" checked={checked} onChange={(e) => settings.setKpiState({ showCustomerTakt: e.target.checked })} /><label htmlFor="checkbox-customerTakt" />
            {i18n.t("workflows.valueStream.showCustomerTakt")}
        </label>
    </div>;
}
