import { get, uniq } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import colors from "../../colors.json";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useResizeObserver } from "../../hooks/UseResizeObserver";
import i18n from "../../i18n";
import { PerTimeperiodDeviationStatisticsSchema, PerTimeperiodEdgeStatisticsSchema, PerTimeperiodEquipmentStatisticsSchema, PerTimeperiodNodeStatisticsSchema, PerTimeperiodStatisticsSchema, TimePeriodFrequencies } from "../../models/ApiTypes";
import { Point } from "../../models/Dfg";
import { Formatter, FormatterParams, UnitScale } from "../../utils/Formatter";
import { Timestamp, addStep, floorTime, timestampSort, toUserTimezone, toUserTimezoneMillis, toUtcJs } from "../../utils/TimezoneUtils";
import { isNiceNumber, randomUUID } from "../../utils/Utils";
import { getDeltaPercent } from "../dashboard-tile/DashboardTile";
import { Layouter } from "../dfg/Layouter";
import { Portal } from "../portal/Portal";
import { Tick, generateGraphTicksByScale } from "./GraphCommon";
import { DataGapFilling } from "../../models/Kpi";

const dataColor = colors.$actual;
const planColor = colors.$plan;


export type LineGraphData = {
    x: number;
    y: number | undefined;
    label?: string;
}

/**
 * Helper hook that extracts a given path from the data and returns it as a LineGraphData array.
 */
export function useLineGraphData(data: PerTimeperiodEdgeStatisticsSchema |
    PerTimeperiodDeviationStatisticsSchema |
    PerTimeperiodStatisticsSchema |
    PerTimeperiodEquipmentStatisticsSchema |
    PerTimeperiodNodeStatisticsSchema |
    undefined,

path: string, options: {
        /**
         * Optional scaling factor @default 1
         */
        scale?: number,

        /**
         * Optional, line graph will display at least this range. If omitted, the minimum time of the data is used.
         */
        min?: Timestamp;

        /**
         * Optional, line graph will display at most this range. If omitted, the maximum time of the data is used.
         */
        max?: Timestamp;

        /**
         * Frequency of the x-axis ticks.
         */
        frequency?: TimePeriodFrequencies;
    }): LineGraphData[] | undefined {
    const session = useContext(SessionContext);

    return useMemo(() => {
        if (data === undefined)
            return undefined;

        const arr = (data as PerTimeperiodEdgeStatisticsSchema)?.edges?.[0]?.timeperiods ??
            (data as PerTimeperiodNodeStatisticsSchema)?.nodes?.[0]?.timeperiods ??
            (data as PerTimeperiodDeviationStatisticsSchema)?.timeperiods ??
            (data as PerTimeperiodStatisticsSchema)?.timeperiods ??
            (data as PerTimeperiodEquipmentStatisticsSchema)?.equipment?.[0]?.timeperiods ??
            [];

        if (options.frequency === undefined)
            // Without frequency there's only so much we can do
            return arr.map((d: any) => {
                return {
                    x: d?.timeperiodStartTime !== undefined ? new Date(d.timeperiodStartTime).getTime() : undefined,
                    y: get(d, path),
                };
            })
                .filter(d => isNiceNumber(d.x) && isNiceNumber(d.y))
                .map(v => { return { x: v.x, y: v.y * (options?.scale ?? 1) }; })
                .sort((a, b) => a.x! - b.x!) as LineGraphData[];

        // Prepare timestamp iteration data
        const tsMap: { [key: string]: { timestamp: Timestamp, element: any } } = {};
        let tsMin: Timestamp | undefined = undefined;
        let tsMax: Timestamp | undefined = undefined;
        arr.forEach(d => {
            const timestamp = toUserTimezone(d.timeperiodStartTime, session.timezone);
            tsMap[timestamp.toString()] = { timestamp, element: d };
            tsMin = tsMin ? timestampSort(tsMin, timestamp) < 0 ? tsMin : timestamp : timestamp;
            tsMax = tsMax ? timestampSort(tsMax, timestamp) > 0 ? tsMax : timestamp : timestamp;
        });

        if (tsMin === undefined || tsMax === undefined)
            // No range specified in options, and no data available. There's nothing we can do!
            return [];

        const [from, to] = [options.min ?? tsMin, options.max ?? tsMax].sort(timestampSort);

        const result: LineGraphData[] = [];
        for (let itr = from; timestampSort(itr, to) < 1; itr = addStep(itr, session.timezone, options.frequency)) {
            const element = tsMap[itr.toString()];
            const x = toUtcJs(itr, session.timezone).getTime();
            const y = get(element?.element, path);
            result.push({ x, y: isNiceNumber(y) ? y * (options?.scale ?? 1) : undefined });
        }
        return result;
    }, [data, path]);
}


export type LineGraphProps = {
    /**
     * Width of the graph. If omitted, the graph will use the entire space
     * provided by it's parent (in that case, set position: relative).
     */
    width?: number;

    /**
     * Height of the graph. If omitted, the graph will use the entire space
     * provided by it's parent  (in that case, set position: relative).
     */
    height?: number;

    unitScale: UnitScale;

    data: LineGraphData[];

    planData?: LineGraphData[];

    dataGapFilling?: DataGapFilling;

    xAxisWidth?: number;

    yAxisHeight?: number;

    axisPadding?: number;

    formatterParams?: FormatterParams;

    xAxisTickFrequency: TimePeriodFrequencies;

    fill?: boolean;
};

type PopupState = {
    isVisible: boolean;
    value?: number;
    deltaPercent?: number;
    deltaPercentFormatted?: string;
    plan?: number;
    pos?: Point;
    absPos?: Point;
    time?: number;
    timeFormatted?: string;
}

export function LineGraph(props: LineGraphProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const svgRef = useRef<SVGSVGElement>(null);
    const hostRef = useRef<HTMLDivElement>(null);
    const resizeState = useResizeObserver(hostRef);

    // Unique ID for this graph instance
    const [id] = useState<string>(() => randomUUID());
    const actualGradientId = props.fill ? `gradient-value-${id}` : undefined;
    const planGradientId = props.fill ? `gradient-plan-${id}` : undefined;

    const [popupState, setPopupState] = useState<PopupState>({
        isVisible: false,
    });

    const axisPadding = props.axisPadding ?? 10;
    const yAxisHeight = props.yAxisHeight ?? 12;

    if (!props.data?.length)
        return null;

    const data = fillGaps(props.data)!;
    const planData = fillGaps(props.planData);

    const canvasHeight = resizeState?.height ? resizeState?.height - yAxisHeight - 2 * axisPadding : 0;

    const svgBackground: JSX.Element[] = [];
    const svgForeground: JSX.Element[] = [];
    const htmlElements: JSX.Element[] = [];

    const allData = data.concat(planData ?? []);
    if (allData.length === 0)
        return null;

    // Y Axis
    const allValues = allData.filter(d => isNiceNumber(d.y)).map(d => d.y!);
    const valueRange = {
        min: Math.min(0, ...allValues),
        max: Math.max(...allValues),
    };

    const yTicks = generateGraphTicksByScale(canvasHeight,
        valueRange.min,
        valueRange.max,
        false,
        props.unitScale, {
            locale: session.locale,
            baseQuantity: settings.quantity,
        }, (value, params) => Formatter.getFormattedValue(props.unitScale, value, params.numDigits ?? 0, params.locale));

    const yMin = Math.min(valueRange.min, valueRange.max, ...yTicks.map(t => t.value));
    const yMax = Math.max(valueRange.min, valueRange.max, ...yTicks.map(t => t.value));

    // Get max width of y ticks
    const yTickWidths = yTicks.map(t => Layouter.measureFontSize("lineGraphTickLabelMeasure", t.label ?? "").width);
    const xAxisWidth = yTickWidths.length > 0 ? Math.max(...yTickWidths) : 0;

    const canvas = {
        left: xAxisWidth + axisPadding,
        top: axisPadding,
        width: resizeState?.width ? resizeState.width - xAxisWidth - 2 * axisPadding : 0,
        height: canvasHeight,
    };

    for (const tick of yTicks) {
        const y = valueToY(tick.value);
        htmlElements.push(<div
            key={`ytick-${tick.value}`}
            className="tickLabel yTickLabel"
            style={{
                top: `${y}px`,
                width: xAxisWidth,
                left: 0,
            }}>
            {tick.label}
        </div>);

        svgBackground.push(<line
            key={`ytick-line-${tick.value}`}
            x1={axisPadding}
            x2={canvas.width + axisPadding}
            y1={y}
            y2={y}
            strokeWidth={1}
            stroke="rgba(0,0,0,0.075)"
        />);
    }

    // X Axis
    const xMin = Math.min(...allData.map(e => e.x));
    const xMax = Math.max(...allData.map(e => e.x));
    const xTicks = getTimeTicks(xMin, xMax, props.xAxisTickFrequency, session.timezone);
    const xTickPlacements = xTicks.length > 0 ? placeTicks(props.xAxisTickFrequency, xTicks) : [];

    for (const tick of xTickPlacements) {
        htmlElements.push(<div
            key={`xtick-${tick.value}`}
            className="tickLabel xTickLabel"
            style={{
                top: `${canvas.height + 2 * axisPadding}px`,
                left: `${valueToX(tick.value) + xAxisWidth}px`,
            }}>
            {tick.label ?? tick.value}
        </div>);
    }

    // Popup data line bubbles
    if (popupState.isVisible && popupState.pos !== undefined) {
        if (popupState.value !== undefined) {
            const valueY = valueToY(popupState.value);
            svgForeground.push(<circle key="value-dot" cx={popupState.pos.x} cy={valueY} r={7} fill={dataColor} />);
        }

        if (popupState.plan !== undefined) {
            const valueY = valueToY(popupState.plan);
            svgForeground.push(<circle key="planning-dot" cx={popupState.pos.x} cy={valueY} r={7} fill={planColor} />);
        }
    }

    const pillClass = ["pill"];
    if (popupState.deltaPercent !== undefined) {
        if (popupState.deltaPercent < 0)
            pillClass.push("pillDown");
        else if (popupState.deltaPercent > 0)
            pillClass.push("pillUp");
        else
            pillClass.push("pullStraight");
    }

    const [linesAcutal, polyActual] = renderDataLines(data, dataColor, actualGradientId);
    const [linesPlan, polyPlan] = renderDataLines(planData, planColor, planGradientId);

    const dataValues = data?.filter(l => isNiceNumber(l.y)).map(l => l.y!) ?? [0];
    const maxValue = Math.max(...dataValues);
    const maxPlan = Math.max(...(planData?.length ? planData?.filter(l => isNiceNumber(l.y)).map(l => l.y!) : [0])!);
    const actualGradientTop = Math.max(0, Math.min(100, 100 * valueToY(maxValue)! / (canvasHeight || 1)));
    const planGradientTop = Math.max(0, Math.min(100, 100 * valueToY(maxPlan)! / (canvasHeight || 1)));

    return <div
        ref={hostRef}
        className="lineGraph"
        onMouseMove={mouseMoveHandler}
        onMouseLeave={() => {
            setPopupState({
                isVisible: false,
            });
        }}
        style={{
            width: props.width ? props.width + "px" : "100%",
            height: props.height ? props.height + "px" : "100%",
        }}>

        <svg
            className="canvas"
            ref={svgRef}
            style={{
                top: 0,
                left: `${xAxisWidth}px`,
                width: `${canvas.width + axisPadding * 2}px`,
                height: `${canvas.height + axisPadding * 2}px`,
            }}
        >
            <defs>
                {props.fill === true && <>
                    <linearGradient id={actualGradientId} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset={`${actualGradientTop}%`} style={{
                            stopColor: colors.$lineGraphGradientActual,
                            stopOpacity: 0.1,
                        }} />
                        <stop offset="100%" style={{
                            stopColor: colors.$white,
                            stopOpacity: 0,
                        }} />
                    </linearGradient>

                    <linearGradient id={planGradientId} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset={`${planGradientTop}%`} style={{
                            stopColor: colors.$lineGraphGradientPlan,
                            stopOpacity: 0.1,
                        }} />
                        <stop offset="100%" style={{
                            stopColor: colors.$white,
                            stopOpacity: 0,
                        }} />
                    </linearGradient>
                </>}
            </defs>
            {canvas.height > 0 && <>
                {props.fill && polyPlan}
                {props.fill && polyActual}
                {svgBackground}
                {linesPlan}
                {linesAcutal}
                {svgForeground}
            </>}
        </svg>

        {canvas.height > 0 && htmlElements}

        <Portal rootId="popup-root">
            {useMemo(() => <>{popupState.isVisible && <div
                style={{
                    left: popupState.absPos!.x + xAxisWidth,
                    top: popupState.absPos!.y,
                }}
                className="lineGraphPopup">
                <div className="title">
                    <div className="time">
                        {popupState.timeFormatted}
                    </div>
                    {popupState.deltaPercentFormatted !== undefined && <div className={pillClass.join(" ")}>
                        <svg className="svg-icon tiny">
                            <use xlinkHref="#arrow-slim-up" />
                        </svg>
                        <span>
                            {popupState.deltaPercentFormatted}
                        </span>
                    </div>}
                </div>
                {popupState.value !== undefined && <div className="row">
                    <div
                        style={{
                            backgroundColor: dataColor,
                        }}
                        className="dot" />
                    <div>
                        {Formatter.formatSpecificUnit(props.unitScale, popupState.value, 1, " ", session.numberFormatLocale)}
                    </div>
                </div>}

                {popupState.plan !== undefined && <div className="row">
                    <div
                        style={{
                            backgroundColor: planColor,
                        }}
                        className="dot" />
                    <div>
                        {Formatter.formatSpecificUnit(props.unitScale, popupState.plan, 1, " ", session.numberFormatLocale)}
                    </div>
                </div>}
            </div>
            }</>, [
                popupState.isVisible,
                popupState.absPos?.x,
                popupState.absPos?.y,
                popupState.deltaPercentFormatted,
                popupState.plan,
                popupState.timeFormatted,
                popupState.value,
            ])}
        </Portal>
    </div>;

    function renderDataLines(data: LineGraphData[] | undefined, color: string, gradient?: string) {
        const lines: JSX.Element[] = [];
        const polys: JSX.Element[] = [];

        if (((data ?? []).length) === 0)
            return [lines, polys];

        const dataPoints: { x: number, y: number | undefined }[] = data?.map(d => ({ x: valueToX(d.x), y: valueToY(d.y) })) ?? [];
        const filteredDatapoints = dataPoints.filter(d => isNiceNumber(d.y));
        if (!dataPoints.length)
            return [[], []];

        if (gradient && filteredDatapoints.length >= 1) {
            const allValues = dataPoints.map(d => d.y).filter(v => v !== undefined) as number[];
            const yBottom = Math.max(...allValues) + 50;
            const points = [...dataPoints.filter(d => isNiceNumber(d.y)), {
                x: Math.min(...(filteredDatapoints ?? [{ x: 0 }]).map(d => d.x)),
                y: yBottom,
            }, {
                x: Math.max(...(filteredDatapoints ?? [{ x: 0 }]).map(d => d.x)),
                y: yBottom,
            }];
            const poly = <polygon key={`poly-${color}`} points={points.map(p => `${p.x},${p.y}`).join(" ")} fill={`url(#${gradient})`} stroke="transparent" />;
            polys.push(poly);
        }

        for (let i = 0; i < dataPoints.length; i++) {
            const current = dataPoints[i];
            const hasCurrent = isNiceNumber(current.y!);

            const prev = dataPoints[i - 1];
            const hasPrev = isNiceNumber(prev?.y);
            const hasNext = isNiceNumber(dataPoints[i + 1]?.y);

            if (!hasPrev && !hasNext && hasCurrent)
                // Isolated datapoint. Render as a circle
                lines.push(<circle
                    key={`datapoint-${i}-${color}`}
                    cx={current.x}
                    cy={current.y}
                    r={1.5}
                    fill={color}
                />);

            if (hasPrev && hasCurrent)
                lines.push(<line
                    key={`line-${i}-${color}`}
                    x1={prev.x}
                    x2={current.x}
                    y1={prev.y}
                    y2={current.y}
                    strokeLinecap="round"
                    strokeWidth={2}
                    stroke={color}
                />);
        }

        return [lines, polys];
    }

    function valueToY(value: number | undefined) {
        if (value === undefined)
            return undefined;

        const dy = yMax - yMin;
        if (dy === 0)
            return axisPadding + canvas.height / 2;

        const f = (value - yMin) / (dy || 1);
        return axisPadding + canvas.height - f * canvas.height;
    }

    function valueToX(value: number) {
        const dx = xMax - xMin;

        if (dx === 0)
            return canvas.width / 2 + axisPadding;

        const f = (value - xMin) / (dx || 1);
        return f * canvas.width + axisPadding;
    }

    /**
     * Returns true if space for this tick is available. If so, (or if force === true),
     * it reserves the space for it.
     */
    function reserveTickSpace(tickMap: Set<number>, tick: Tick, textPadding = 2, force = false) {
        const size = Layouter.measureFontSize("lineGraphTickLabelMeasure", tick.label ?? "");
        const position = Math.round(valueToX(tick.value));
        const left = Math.floor(position - size.width / 2 - textPadding);
        const right = Math.ceil(position + size.width / 2 + textPadding);

        if (!force) {
            // if (left < 0 || right >= (canvas.width + 2 * axisPadding))
            //     return false;

            let intersects = false;
            for (let i = left; i <= right && !intersects; i++)
                intersects = tickMap.has(i);

            if (intersects)
                return false;
        }

        // Reserve space
        for (let i = left; i <= right; i++)
            tickMap.add(i);

        return true;
    }

    function placeTicks(tickFrequency: TimePeriodFrequencies, ticks: Tick[]) {
        const textPadding = 2;
        const result: Tick[] = [];

        // Get a step size where tick labels do not overlap
        const stepSize = (() => {
            const stepSizes = {
                [TimePeriodFrequencies.Day]: [1, 2, 7, 14, 28],
                [TimePeriodFrequencies.Month]: [1, 2, 3, 4, 6, 12, 24],
                [TimePeriodFrequencies.Week]: [1, 2, 4, 8, 12, 16, 20, 24],
                [TimePeriodFrequencies.Year]: [1, 2, 5, 10, 25, 100],
            }[tickFrequency];

            for (const stepSize of stepSizes) {
                const tickMap = new Set<number>();
                let isOkay = true;
                for (let i = 0; i < ticks.length && isOkay; i += stepSize)
                    isOkay = reserveTickSpace(tickMap, ticks[i]);

                if (isOkay)
                    return stepSize;
            }

            // If even the largest step size is not enough, that's still better than
            // nothing I guess
            return stepSizes[stepSizes.length - 1];
        })();

        const tickMap = new Set<number>();
        if (ticks.length > 0) {
            reserveTickSpace(tickMap, ticks[0], textPadding, true);
            result.push(ticks[0]);
        }

        if (ticks.length > 1) {
            reserveTickSpace(tickMap, ticks[ticks.length - 1], textPadding, true);
            result.push(ticks[ticks.length - 1]);
        }


        for (let i = 0; i < ticks.length; i += stepSize)
            if (reserveTickSpace(tickMap, ticks[i], textPadding))
                result.push(ticks[i]);

        return result;
    }

    function mouseMoveHandler(event: React.MouseEvent<any, MouseEvent>) {
        const rect = svgRef.current!.getBoundingClientRect();
        const x = event.pageX - rect.left;

        let minDelta = Number.MAX_SAFE_INTEGER;
        let time = -1;
        const timestamps = uniq((data?.map(d => d.x) ?? []).concat(planData?.map(d => d.x) ?? []));
        for (let i = 0; i < timestamps.length; i++) {
            const p = timestamps[i];
            const dx = Math.abs(valueToX(p) - x);
            if (dx < minDelta) {
                minDelta = dx;
                time = timestamps[i];
            }
        }

        // Find closest datapoint
        if (time >= 0) {
            const value = data?.find(d => d.x === time)?.y;
            const planValue = planData?.find(d => d.x === time)?.y;

            const deltaPercent = getDeltaPercent(value, data[time + 1]?.y);

            // Get top left corner of the chart
            const chartRect = hostRef.current!.getBoundingClientRect();

            const values = ([value, planValue].filter(v => v !== undefined) as number[]).map(v => valueToY(v)!);
            if (values.length) {
                const yMin = Math.min(...values);
                const x = valueToX(time);
                const y = yMin - 110;
                setPopupState({
                    isVisible: true,
                    pos: { x, y },
                    absPos: {
                        x: x + chartRect.left,
                        y: y + chartRect.top,
                    },
                    value,
                    deltaPercent,
                    deltaPercentFormatted: deltaPercent !== undefined ? Formatter.formatPercent(deltaPercent, 1, 1, session.numberFormatLocale) : undefined,
                    plan: planValue,
                    time,
                    timeFormatted: Formatter.formatTime(props.xAxisTickFrequency,
                        toUserTimezoneMillis(time, session.timezone),
                        session.timezone),
                });
            }
        }
    }

    function fillGaps(dataPoints: LineGraphData[] | undefined) {
        if (dataPoints === undefined)
            return undefined;

        switch (props.dataGapFilling) {
            case DataGapFilling.Default:
                return dataPoints.filter(d => isNiceNumber(d.y!));

            case DataGapFilling.Zero:
                return dataPoints.map(d => {
                    return {
                        ...d,
                        y: !isNiceNumber(d.y!) ? 0 : d.y,
                    };
                });
        }
        return dataPoints;
    }
}

/**
 * Generates x-axis ticks
 * @param from range minimum
 * @param to range maximum
 * @param tickFrequency tick intervals
 * @param width width of the widgets in pixels
 * @param tz timezone
 */
export function getTimeTicks(from: number, to: number, tickFrequency: TimePeriodFrequencies, tz: string): Tick[] {
    const rangeFrom = toUserTimezoneMillis(from, tz);
    const rangeTo = toUserTimezoneMillis(to, tz);

    const result: { [key: string]: Tick } = {};

    // Find intervals that fall into that boundary
    let ts = floorTime(from, tickFrequency, tz);
    while (timestampSort(ts, rangeTo) < 1) {
        const key = ts.toString();
        if (result[key] === undefined &&
            timestampSort(ts, rangeFrom) >= 0)
            // Make a tick
            result[key] = {
                value: toUtcJs(ts, tz).getTime(),
                label: formatTime(tickFrequency, ts, tz),
            };

        ts = addStep(ts, tz, tickFrequency);
    }

    return Object.values(result);
}

function formatTime(interval: TimePeriodFrequencies, time: Timestamp, timezone: string) {
    const formatString = {
        [TimePeriodFrequencies.Day]: i18n.t("datetime.formats.day"),
        [TimePeriodFrequencies.Week]: i18n.t("datetime.formats.week"),
        [TimePeriodFrequencies.Month]: i18n.t("datetime.formats.monthShort"),
        [TimePeriodFrequencies.Year]: i18n.t("datetime.formats.year"),
    }[interval];

    return Formatter.formatTimePlaceholders(formatString, time, timezone);
}
