import React, { useContext } from "react";
import { GraphDetailedControls } from "../../components/controls/GraphDetailedControls";
import { GroupingKeyControls, VisibilityOptions, getValidGroupingKeyControlSettings } from "../../components/controls/GroupingKeyControls";
import { ObjectControls, getValidObjectControlsSettings } from "../../components/controls/ObjectControls";
import { SettingsContext, SettingsType, VisualizationType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { SessionContext, SessionContextType, SessionType } from "../../contexts/SessionContext";
import { isObjectCentricDeviationAvailable } from "../../utils/SettingsUtils";
import { Spotlight } from "../../components/spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { KpiPresets, KpiTypes } from "../../models/KpiTypes";
import { KpiControls, getValidKpiControlSettings } from "../../components/controls/KpiControls";
import { StatisticTypes } from "../../models/KpiTypes";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import { GraphHighlightCheckbox } from "../../components/controls/HighlightControls";

export function getValidDeviationGraphControlSettings(session: SessionType, settings: SettingsType): DeepPartial<SettingsType> {
    const result: DeepPartial<SettingsType> = {};

    const isObjectCentricDeviationAllowed = session.project !== undefined && isObjectCentricDeviationAvailable(session.project);

    return ObjectMerger.mergePartialObjects([
        result,
        getValidKpiControlSettings(session, settings, {
            kpis: KpiPresets.valueStreamTimeDeviationKpis,
            hasYAxis: false,
            addQuantityDropdown: true,
            hideStatistics: [StatisticTypes.Variance],
        }),
        getValidObjectControlsSettings(session, settings),
        getValidGroupingKeyControlSettings(session, settings, {
            options: {
                objectType: isObjectCentricDeviationAllowed ? VisibilityOptions.Visible : VisibilityOptions.Hidden,
                none: VisibilityOptions.Hidden
            }
        }),
    ]);
}

export default function ControlsDeviationGraph() {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const location = useLocation();

    const isObjectCentricDeviationAllowed = session.project !== undefined && isObjectCentricDeviationAvailable(session.project);


    return <div className="controls">

        {/* Analyzed Values */}
        <KpiControls kpis={getDeviationKpis(session)} hasYAxis={false} addQuantityDropdown={true} hideStatistics={[StatisticTypes.Variance]} />

        <GraphHighlightCheckbox />

        <ObjectControls showContextSwitch={true} disableObjectType={!isObjectCentricDeviationAllowed} />


        {/* Grouping*/}
        <GroupingKeyControls
            options={{
                objectType: isObjectCentricDeviationAllowed ? VisibilityOptions.Visible : VisibilityOptions.Hidden,
                none: VisibilityOptions.Hidden,
            }}
        />

        {/* Node detail level*/}
        <GraphDetailedControls />

        {/* Visualization */}
        <div className="section deviationDfgSwitch">
            <div className="title">
                {i18n.t("common.visualization")}
                <Spotlight id={buildControllerSpotlightId(location.pathname, ["visualization"])} className="mls" />
            </div>

            <div className="buttons-2col">
                <button id="button-visualization-sidebyside" data-testid="button-visualization-sidebyside" onClick={() => { settings.setGraph({ visualization: VisualizationType.SideBySide }); }} className={settings.graph.visualization === VisualizationType.SideBySide ? "active" : ""}>{i18n.t("workflows.planningDeviation.sideBySide")}</button>
                <button id="button-visualization-single" data-testid="button-visualization-single" onClick={() => { settings.setGraph({ visualization: VisualizationType.Single }); }} className={settings.graph.visualization === VisualizationType.Single ? "active" : ""}>{i18n.t("workflows.planningDeviation.aggregated")}</button>
            </div>
        </div>
    </div>;
}


export function getDeviationKpis(session: SessionContextType) {
    // When the project uses the routings data to calculate deviations,
    // the activityPassTimeStatistics / activityPassFrequencyStatistics are not present.
    // So, the throughput time and number of passes cannot be displayed.
    const isUsingRoutings = session.project?.uploads?.routings !== undefined;

    return KpiPresets.valueStreamTimeDeviationKpis.filter(kpi => {
        return !isUsingRoutings || [KpiTypes.ThroughputTime, KpiTypes.Frequency].indexOf(kpi) < 0;
    });
}