import { get, isFunction } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { defaultProductLimit } from "../../Global";
import { CalculateOptions, CustomKpi, ProductCaseAggregationStatistics, ProductDeviationStatisticsSchema, allTimeOptions } from "../../models/ApiTypes";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext, SessionType, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { KpiDefinition, getTimeperiodStatisticPath, getKpiDefinition, getStatisticSymbol, getUnit, hasProductCustomKpi } from "../../models/Kpi";
import { StatsTypes } from "../../models/Stats";
import { buildProductFilter } from "../../utils/FilterBuilder";
import { UnitMetadata } from "../../utils/Formatter";
import { getDefaultEnabledComparisons, getProductPathFromDefinition } from "../../utils/SettingsUtils";
import { getLegend } from "../../views/process-kpi-chart/ProductProcessKpiChart";
import DownloadFile, { TemplateType } from "../download-file/DownloadFile";
import { ContainerModes, commonSelectionLineProps } from "../graph/GraphCommon";
import { Bar, GroupGraph } from "../graph/GroupGraph";
import { NoDataAvailable } from "../no-data-available/NoDataAvailable";
import { NotificationService } from "../notification/NotificationService";
import Spinner from "../spinner/Spinner";
import { useProductStats } from "../../hooks/UseProductStats";
import { KpiTypes, SortOrder, StatisticTypes } from "../../models/KpiTypes";
import { useStatistics } from "../../hooks/UseStatistics";
import { getMessage } from "../../views/process-kpi-chart/ProcessKpiChart";
import colors from "../../colors.json";

export type ChartProps<REQUEST_TYPE> = {
    requestOptions?: Partial<REQUEST_TYPE>;

    height: number;

    width: number;

    /**
     * Title above the chart
     */
    title: ((settings: SettingsType) => string) | string | unknown;

    /**
     * Unit to use for the chart
     */
    unit?: UnitMetadata | ((settings: SettingsType) => UnitMetadata);

    /**
     * In case the API did not return any products, this message will be displayed.
     * It needs to be a translation string based on {{count}} that is defined for `_one` and `_other`.
     */
    noDataPlaceholder: string,

    /**
     * Gets the property name of the value to use as primary product value.
     * @param settings The current settings
     * @param isDeviationApi Whether the deviation api is used. If so, you might have to prefix the
     *                       property name with "actual." or "planned."
     */
    getValueProp?: (session: SessionType, settings: SettingsType, isDeviationApi: boolean) => string | undefined;

    /**
     * Gets the property name of the value to use as secondary product value
     * @param settings The current settings
     * @param isDeviationApi Whether the deviation api is used. If so, you might have to prefix the
     *                       property name with "actual." or "planned."
     */
    getComparisonProp?: (settings: SettingsType, isDeviationApi: boolean,) => string | undefined;

    /**
     * Callback that returns the property by which the sorting should be conducted.
     * No need to take care of ascending/descending, that's done by the component.
     */
    getSortProp?: (session: SessionType, settings: SettingsType) => string;

    /**
     * We pass this flag on to the underlying hooks. This is relevant for the energy-
     * related hacks living there.
     */
    addEnergyStats?: boolean;

    /**
     * In case we need to disable requests in some initializations we can set this to true.
     */
    disableRequest?: boolean;

    singleObjectStatsValid?: boolean;
};

export type ProductChartProps = ChartProps<CalculateOptions>;


/**
 * This class is meant to be a base class for all product charts. It comes loaded with reasonable defaults, but
 * whenever it comes to extending it, please make things pluggable. Provide callbacks like e.g. "getProductLabel"
 * instead of awkward and highly specific properties like "showAlternativeDeviationLabels".
 * Also, keep in mind, that functions that return JSX render JSX on demand, and all hooks used are executed
 * only on demand, whereas when providing JSX directly, hooks are always executed, even when the JSX is not
 * displayed.
 */export function ProductChart(props: ProductChartProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const isDeviationApi = (settings.kpi.comparisons === KpiComparisons.Planning && kpiDefinition?.allowedComparisons.includes(KpiComparisons.Planning)) || !!kpiDefinition?.useDeviationApi;
    const enableDownload = settings.kpi.comparisons === KpiComparisons.Planning || settings.kpi.comparisons === KpiComparisons.None;
    const downloadAllowed = hasDownloadPermission(session);

    const valueProp = props.getValueProp?.(session, settings, isDeviationApi) ?? getDefaultValueProp(session, settings, isDeviationApi);
    const comparisonProp = props.getComparisonProp?.(settings, isDeviationApi) ?? getDefaultComparisonProp(session, settings, isDeviationApi);
    const customKpis = [getBestProcessDeltaForSorting(session, settings, valueProp, comparisonProp), kpiDefinition?.productCustomKpis].flat().filter(v => v !== undefined) as CustomKpi[];

    const [stats,] = useStatistics(undefined);

    const isComparisonAllowed = getDefaultEnabledComparisons(session, settings)?.includes(settings.kpi.comparisons) ?? false;

    const [productCaseAggregations, productDeviationAggregations, isLoading] = useProductStats(
        isDeviationApi,
        {
            ...allTimeOptions,
            ...props.requestOptions,
            ...kpiDefinition?.apiParameters,
            customKpis: customKpis.length ? customKpis : undefined,
            sort: getSort(session, settings, isDeviationApi, props.getSortProp ?? props.getValueProp ?? getDefaultValueProp),
            limit: defaultProductLimit,

        }, {
            addEnergyStats: props.addEnergyStats,
            disable: props.disableRequest,
        });

    const selectedProductFromData = isDeviationApi ? productDeviationAggregations?.products?.find(x => x.id === settings.selection.product?.id) : productCaseAggregations?.products?.find(x => x.id === settings.selection.product?.id);

    // Indicates if the selected case wasn't found in the data returned
    // by the hook above. If this is the case, we either issue a request
    // for that specific case, or show a notification if filtering would
    // fault the stats.
    const selectedProductMissingInResults = settings.selection.product !== undefined &&
        (productCaseAggregations?.products !== undefined || productDeviationAggregations?.products !== undefined) &&
        selectedProductFromData === undefined;

    const doSelectionRequest = selectedProductMissingInResults &&
        props.singleObjectStatsValid !== false;

    // Show a noticiation when the selected element is filtered out
    const [showNotification, setShowNotification] = useState<boolean>(true);
    useEffect(() => {
        if (productCaseAggregations === undefined ||
            productCaseAggregations?.products?.find(x => x.id === settings.selection.product?.id) !== undefined)
            return;

        // Notification is deactivated for the carbon bar chart
        const showSelectionLineWarning = selectedProductMissingInResults &&
            showNotification &&
            props.singleObjectStatsValid === false && props.addEnergyStats === undefined;

        if (showSelectionLineWarning) {
            NotificationService.add({
                id: "Selected-product-line-invisible",
                summary: i18n.t("kpi.selectionLineProductIsInvisibleTitle"),
                message: <p>{i18n.t("kpi.selectionLineProductIsInvisible")}</p>,
                className: "light default-accent",
                icon: "radix-bell",
                autoCloseDelay: 7000,
            });
            setShowNotification(false);
        }
    }, [
        productCaseAggregations,
        productDeviationAggregations,
    ]);

    const productFilter = buildProductFilter(settings.selection.product, false, session);
    const [selectedProductAggregation, isSelectedProductLoading] = useProductStats(
        settings.kpi.comparisons === KpiComparisons.Planning,
        {
            ...allTimeOptions,
            ...props.requestOptions,
            eventFilters: [productFilter!],
            limit: 1,
        }, {
            addEnergyStats: props.addEnergyStats,
            disable:
            !doSelectionRequest ||
            productFilter === undefined ||
            settings.selection.product === undefined,
        });

    const productData = isDeviationApi ? productDeviationAggregations?.products : productCaseAggregations?.products;

    const isComparisonHighlightingEnabled = settings.kpi.comparisons === KpiComparisons.Planning && settings.kpi.highlightDeviations;

    const data = useMemo(() => {
        if (!productData?.length)
            return [];


        const result: Bar<ProductDeviationStatisticsSchema | ProductCaseAggregationStatistics>[][] = [];
        for (const product of productData ?? []) {
            const value = valueProp !== undefined ? get(product, valueProp) : undefined;
            if (value === undefined)
                continue;



            const group: Bar<ProductDeviationStatisticsSchema | ProductCaseAggregationStatistics>[] = [{
                value,
                data: product,
                label: product.name,
            }];

            if (settings.kpi.comparisons !== KpiComparisons.None) {
                const plannedValue = comparisonProp !== undefined && isComparisonAllowed ? get(product, comparisonProp) : undefined;

                group.push({
                    value: plannedValue ?? 0,
                    valueLabel: plannedValue === undefined ? i18n.t("common.notAvailableAbbreviated").toString() : undefined,
                    data: product,
                    label: product.name,
                });

                if (isComparisonHighlightingEnabled)
                    group[0].barColor = getBarColor(kpiDefinition, value, plannedValue);
            }

            result.push(group);
        }

        return result;
    }, [
        session.project,
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        settings.kpi.relativeToThroughputTime,
        settings.kpi.aggregation,
        settings.kpi.comparisons,
        settings.kpi.analyzedValue,
        settings.kpi.selectedKpi,
        settings.quantity,
        valueProp,
        comparisonProp,
        productData,
        isComparisonHighlightingEnabled,
    ]);


    //Draw selection line
    const selectedProduct = selectedProductFromData ?? selectedProductAggregation?.products[0];

    const selectionLine = useMemo(() => {
        if (selectedProduct) {
            const selectedValue = valueProp !== undefined ? get(selectedProduct, valueProp) : undefined;

            return selectedValue !== undefined ? [{ ...{ value: selectedValue, ...commonSelectionLineProps } }] : [];
        }
        return undefined;
    }, [
        selectedProduct,
        valueProp,
        isDeviationApi,
    ]);

    const hasData = productData !== undefined && productData?.length > 0;
    const headline: string = props.title === undefined ? "" :
        isFunction(props.title) ? props.title(settings) : props.title;

    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    return <>
        <Spinner isLoading={isLoading} showProjectLoadingSpinner={true} />

        {productData !== undefined && !isLoading && !hasData && (getMessage(props.noDataPlaceholder, stats?.numFilteredTraces, isDeviationApi) ?? <NoDataAvailable visible={true} title="common.noResults" message="" />)}

        {hasData && !isLoading && <>
            <GroupGraph
                width={props.width}
                height={props.height}
                horizonalLines={isSelectedProductLoading ? undefined : settings.selection.product ? selectionLine : []}
                title={i18n.t(headline).toString()}
                padding={{
                    top: 85,
                    left: 60,
                    bottom: 100,
                }}
                legend={getLegend(settings, isComparisonHighlightingEnabled)}
                barPadding={10}
                minGroupPadding={50}
                showYAxisLines={true}
                yAxisLabel={getAxisLabel(settings.kpi.selectedKpi, settings.kpi.statistic, session, settings)}
                selectedGroupIdx={data.findIndex(d => d[0].data?.id === settings.selection.product?.id)}
                selectedGroupBarIdx={0}
                onSelected={(groupIdx, barIdx, data) => {
                    if (settings.selection.product?.id === data?.id || !data?.id)
                        settings.setSelection({});
                    else
                        settings.setSelection({
                            product: {
                                id: data.id,
                                name: data.name,
                            }
                        });
                }}
                onLabelSelected={(groupIdx) => {
                    const element = data[groupIdx][0].data;
                    if (settings.selection.product?.id === element?.id || !element?.id)
                        settings.setSelection({});
                    else
                        settings.setSelection({
                            product: {
                                id: element.id,
                                name: element.name,
                            }
                        });
                }}
                showBarValues={true}
                yAxisUnit={unit}
                valueFormatter={(value) => {
                    // This is not one of my proudest moments as a developer, but we're doing this to avoid
                    // overlapping bar value labels by allowing the units to wrap after the usually long "pieces" word.
                    if (unit)
                        return unit.formatter(value, {
                            numDigits: 1,
                            locale: session.numberFormatLocale,
                            baseQuantity: settings.quantity
                        }).replace("/", "\u200b/");
                    return "";
                }}
                data={data}
                containerMode={ContainerModes.Constrained}
            />
            {enableDownload && <DownloadFile
                data={data}
                planningData={settings.kpi.comparisons === KpiComparisons.Planning}
                template={TemplateType.Product}
                meta={unit}
                allowed={downloadAllowed}
                title={i18n.t(headline).toString()} />}
        </>}
    </>;
}

export function getBarColor(kpiDefinition?: KpiDefinition, value?: number, comparisonValue?: number) {
    if (kpiDefinition === undefined || value === undefined || !isFinite(value))
        return colors.$graphPositiveValueColor;

    if (comparisonValue === undefined || !isFinite(comparisonValue))
        return colors.$unplanned;

    if (value === comparisonValue)
        return colors.$setupGraphActualFaster;

    if (value < comparisonValue)
        return kpiDefinition.isLessBetter ? colors.$setupGraphActualFaster : colors.$setupGraphActualSlower;

    return kpiDefinition.isLessBetter ? colors.$setupGraphActualSlower : colors.$setupGraphActualFaster;
}

/**
 * This function returns the path to the required value by checking the KPI definitions.
 * It prefixes the path with "actual" or "planned" if the deviation api is used.
 */
export function getDefaultComparisonProp(session: SessionType, settings: SettingsType, isDeviationApi: boolean) {

    if (settings.kpi.comparisons === KpiComparisons.None)
        return;

    if (settings.kpi.comparisons === KpiComparisons.Planning)
        return getDefaultValueProp(session, settings, isDeviationApi, "planned");

    if (settings.kpi.comparisons === KpiComparisons.BestProcesses) {
        const isLessBetter = getKpiDefinition(settings.kpi.selectedKpi, { session, settings })?.isLessBetter;
        // We use the p25 value for the best processes comparison if the kpi is less better.
        if (isLessBetter)
            return getDefaultValueProp(session, settings, isDeviationApi, "actual", "p25");
        // We use the p75 value for the best processes comparison if the kpi is not less better.
        return getDefaultValueProp(session, settings, isDeviationApi, "actual", "p75");
    }
}

export function getDefaultValueProp(session: SessionType, settings: SettingsType, isDeviationApi: boolean, deviationPrefix: "actual" | "planned" | "deviation" = "actual", statsProperty?: StatsTypes) {

    const analyzedValueSetting = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings })!;

    const timeperiodPath = getTimeperiodStatisticPath(kpiDefinition, settings.kpi.statistic);
    const productPath = getProductPathFromDefinition(settings, analyzedValueSetting, statsProperty);

    const path = (settings.kpi.aggregation === AggregationTypes.Time) ?
        timeperiodPath ?? productPath : productPath;
    if (!path)
        return;
    // We add the statistics prefix if we are using the deviation api.
    // This is not the case for caseCount because it is defined on a higher level.
    const addPrefix = (isDeviationApi && !(path === "caseCount") && !analyzedValueSetting?.useDeviationApi);
    // This is a workaround as long as the backend does use different names for count in the deviation endpoint.
    if (!isDeviationApi && path === "caseCount")
        return "count";
    return `${addPrefix ? deviationPrefix + "." : ""}${path}`;
}

/**
 * Get the sort property based on settings and a getValueProp callback.
 */
export function getSort(session: SessionType, settings: SettingsType, isDeviationApi: boolean, getValueProp: (session: SessionType, settings: SettingsType, isDeviationApi: boolean) => string | undefined) {
    const sortPropName = getSortPropName(session, settings, isDeviationApi, getValueProp);
    if (!sortPropName || sortPropName === "")
        return;

    const orderProp = settings.kpi.sortOrder === SortOrder.Ascending ? "" : "-";
    return [orderProp + sortPropName];
}

function getSortPropName(session: SessionType, settings: SettingsType, isDeviationApi: boolean, getValueProp: (session: SessionType, settings: SettingsType, isDeviationApi: boolean) => string | undefined) {
    switch (settings.kpi.sortBy) {
        case SortByType.Kpi: {
            // deviation api contains actual / planned / deviation as subfields for
            // kpis so we need to add a prefix
            return getValueProp(session, settings, isDeviationApi);
        }
        case SortByType.DeviationFromComparison: {
            if (settings.kpi.comparisons === KpiComparisons.BestProcesses) {
                if (hasProductCustomKpi(session, settings))
                    return;
                return "customKpis.deltaForSorting.value";
            }
            return getValueProp(session, settings, isDeviationApi)?.replace("actual", "deviation");
        }
        case SortByType.Frequency:
            // TODO: In backend this is still up for debate
            return isDeviationApi ? "caseCount" : "count";
        case SortByType.Alphabetical:
            return "name";
    }
}

export function getAxisLabel(analyzedValue: KpiTypes, statistic: StatisticTypes, session: SessionType, settings: SettingsType) {
    const label = getKpiDefinition(analyzedValue, { session, settings })?.label ?? "";
    return `${i18n.t(label)} ${getStatisticSymbol(statistic)}`;
}

//We use this function to adapt the product data to the template excel sheet
export function exportProductData(data: Bar<ProductDeviationStatisticsSchema | ProductCaseAggregationStatistics>[][], planningData?: boolean) {
    const result = [];

    //if planning comparison is selected then we add "Plan" column to the excel sheet
    for (const bar of data) {
        const item = {
            [i18n.t("common.product")]: bar[0]?.label,
            [i18n.t("common.actual")]: bar[0]?.value,
        };

        if (planningData)
            item[i18n.t("common.plan")] = bar[1]?.value;

        result.push(item);
    }

    return result;
}

export function getBestProcessDeltaForSorting(session: SessionType, settings: SettingsType, valueProp?: string, comparisonProp?: string): CustomKpi | undefined {

    // TODO: enable best process sorting also for custom kpis once we can do more 
    // advanced calculations in the backend with more than two arguments.
    if (hasProductCustomKpi(session, settings))
        return;
    if (settings.kpi.comparisons === KpiComparisons.BestProcesses && settings.kpi.sortBy === SortByType.DeviationFromComparison && valueProp && comparisonProp)
        return {
            id: "deltaForSorting",
            definition: `products.${valueProp} - products.${comparisonProp}`,
            target: "products"
        };
    return;

}

export function getAnalysisTitle(session: SessionType, settings: SettingsType) {
    if (settings.kpi.aggregation === AggregationTypes.Case)
        return getKpiDefinition(settings.kpi.selectedKpi, { session, settings })?.label ?? "";
    return `kpi.${settings.kpi.selectedKpi}.${settings.kpi.statistic}`;
}