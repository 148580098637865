import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { analysisGraphMapping, AnalysisType, useGraph } from "../../hooks/UseGraph";
import { GroupingKeys, NodeRoles } from "../../models/Dfg";
import { DfgUtils, getCustomKpisDfg, getCustomKpisEquipmentStats } from "../../utils/DfgUtils";
import { StatsSection } from "../stats-section/StatsSection";
import { EdgeFrequencyStats, EdgeStats, FrequencyStats, NodeStats, ProductStats } from "../stats-section/Utility";
import { getSelectionLabel } from "./SideStatisticsTime";
import { LocalizationInfoStats } from "./LocalizationInfoStats";
import { KpiPresets } from "../../models/KpiTypes";
import { KpiTypes } from "../../models/KpiTypes";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { LogStats } from "../../views/process-kpi-chart/stats/LogStats";
import { useEquipmentStats } from "../../hooks/UseEquipmentStats";
import { EventKeys } from "../../models/EventKeys";
import { getKpiDefinition } from "../../models/Kpi";
import NodeStatsLineGraph from "../graph/NodeStatsLineGraph";
import EquipmentStatsLineGraph from "../graph/EquipmentStatsLineGraph";

export default function SideStatisticsValueStream(props: { kpiList?: KpiTypes[], makePassIdFirst?: boolean }) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === AnalysisType.ValueStream)?.arguments;
    const graphOptions = {
        ...(analysisArguments ?? {}),
        ...getCustomKpisDfg(settings, session, false, props.kpiList ?? KpiPresets.valueStreamTaktTimeKpis),

        calculateActivityValues: true,
        calculateNodes: true,
        calculateEdges: true,
        calculateScores: true,
        calculateRoles: true,
	
    };
    const graph = useGraph(graphOptions, AnalysisType.ValueStream, false);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, {session, settings});

    const disableEquipmentStats = !kpiDefinition?.isEquipmentStatsKpi;

    const disableLineChart = [KpiTypes.CycleTime, KpiTypes.ProductCount, KpiTypes.WorkInProcessInventory,
        KpiTypes.OverallEquipmentEffectiveness].includes(settings.kpi.selectedKpi);

    const equipmentStatsOptions = {
        ...getCustomKpisEquipmentStats(settings, session, [KpiTypes.OverallEquipmentEffectiveness]),
        calculateNodes: true,
        calculateActivityValues: true,
    };

    const [equipmentStats] = useEquipmentStats({
        ...equipmentStatsOptions,
        eventKeys: {
            ...session.project!.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable: disableEquipmentStats ||
            !session.project?.uploadId ||
            !session.project?.eventKeys?.passId,
    });


    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    if (graph === undefined)
        return null;

    const selectedMultiEdge = graph.multiEdges.find(e => e.from === settings.selection.edge?.from && e.to === settings.selection.edge?.to);
    const selectedEdge = (selectedMultiEdge ? DfgUtils.findObjectEdge(selectedMultiEdge, isObjectCentric, settings.graph.objectType) : undefined);
    const node = graph.nodes.find(n => n.id === settings.selection.node?.id);
    const selectedObjectNode = (node ? DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType) : undefined);
    const equipmentStatsNode = equipmentStats?.equipment?.find(n => n.activityValues?.machine?.value === node?.activityValues?.machine?.value);

    const label = getSelectionLabel(graph, settings, props.makePassIdFirst);

    const isTerminalNode = Boolean(settings.selection.node?.role === NodeRoles.Start || settings.selection.node?.role === NodeRoles.End);
    const hasSelection = !isTerminalNode && (selectedObjectNode !== undefined || selectedEdge !== undefined);

    return <>
        <div data-testid="sidePanel">
            {!hasSelection && <LogStats overrideKpi={KpiTypes.ThroughputTime}/>}

            {selectedObjectNode && !isTerminalNode && <StatsSection title={label}>
                <LocalizationInfoStats />
                <NodeStats kpiType={settings.kpi.selectedKpi} node={selectedObjectNode} equipmentStats={equipmentStatsNode} disableSum={settings.kpi.selectedKpi === KpiTypes.CycleTime} />
                {settings.kpi.selectedKpi !== KpiTypes.Frequency && <FrequencyStats node={selectedObjectNode} />}
                {settings.kpi.selectedKpi !== KpiTypes.ProductCount && <ProductStats node={selectedObjectNode} />}
                {!!kpiDefinition?.nodeOverTimeStatisticsPath && !disableLineChart && <NodeStatsLineGraph />}
                {settings.kpi.selectedKpi === KpiTypes.OverallEquipmentEffectiveness && <EquipmentStatsLineGraph kpis={[KpiTypes.OverallEquipmentEffectiveness]} node={equipmentStatsNode?.activityValues?.machine?.value}/>}
            </StatsSection>}

            {selectedEdge && <StatsSection title={label}>
                <LocalizationInfoStats />
                <EdgeStats kpiType={KpiTypes.QueuingTime} edge={selectedEdge} />
                <EdgeFrequencyStats edge={selectedEdge} />

            </StatsSection>}

        </div>
    </>;
}
