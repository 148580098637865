import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../dropdown/Dropdown";
import { Datastores } from "../../utils/Datastores";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import { EventFilter } from "../../models/EventFilter";
import i18n from "../../i18n";
import { noop } from "lodash";

export const ALL_PRODUCTS = "ALL_PRODUCTS";

export function ProductDropdown(props: {
    className?: string;
    filters?: EventFilter[];
    onChange: (product: string) => void;
    value: string;
    placeholder?: string;
    enableAllProductsOption?: boolean;
}) {
    const session = useContext(SessionContext);
    const isOniqUser = isOniqEmployee(session);

    const [products, setProducts] = useState<string[] | undefined>();
    const [subscriptionId] = useState<number>(() => {
        return Datastores.distinctAttributeValues.getSubscriptionId();
    });

    useEffect(() => {
        return () => {
            Datastores.distinctAttributeValues.cancelSubscription(subscriptionId);
        };
    }, []);

    useEffect(() => {
        Datastores.distinctAttributeValues.get({
            eventKeys: session.project!.eventKeys!,
            eventFilters: props.filters ?? [],
            attributes: [session.project!.eventKeys!.product!],
            uploadId: session.project!.uploadId!,
            uploads: session.project?.uploads,
            limit: 6000,
        }, subscriptionId).then(result => {
            const p = result.find(r => r.name === session.project!.eventKeys!.product!)?.values;
            setProducts(p ? p.map(x => x.value as string) : []);
        }).catch(noop);
    }, [
        JSON.stringify(props.filters),
        session.project?.id,
    ]);

    // If the project does not have products labelled, there's nothing to show.
    if (!session.project?.eventKeys?.product)
        return null;

    const options = (products ?? []).map(p => {
        return {
            label: p.toString(),
            value: p.toString(),
        };
    });

    // Add all products as an option only for oniqDevUsers and if allProductsOption is true.
    if ((isOniqUser || !!session.project.features?.allowGlobalBottleneck) && props.enableAllProductsOption)
        options.unshift({ label: i18n.t("common.allProducts"), value: ALL_PRODUCTS });

    return <>
        <h2>{i18n.t("common.selectProduct")}</h2>
        <Dropdown
            className={props.className}
            options={options}
            onChange={(p) => {
                if (p?.value !== props.value)
                    props.onChange(p!.value! as string);
            }}
            value={options.find(p => p.value === props.value) ?? {
                label: i18n.t(products === undefined ? "common.initializing" : props.placeholder ?? "common.pleaseSelect"),
                value: ""
            }}
            placeholder={"lol"}
            isDisabled={false}
            isSearchable={true}
            testId="product-dropdown"
        />
    </>;
}
