import React, { useContext, useState } from "react";
import { StatsLineGraph } from "./StatsLineGraph";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SessionContext } from "../../contexts/SessionContext";
import { TimePeriodFrequencies } from "../../models/ApiTypes";
import { EventKeys } from "../../models/EventKeys";
import { getCustomKpisEquipmentStats } from "../../utils/DfgUtils";
import { get, isObject } from "lodash";
import { useLineGraphData } from "./LineGraph";
import { KpiDefinition, buildKpiSpotlightId, getKpiDefinition, getUnit } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { useEquipmentAggregationTimeperiods } from "../../hooks/UseEquipmentAggregationTimeperiods";
import { GroupingKeys } from "../../models/Dfg";

type EquipmentStatsLineGraphProps = {
    kpis: KpiTypes[];
    node: string | undefined;
    statistics?: StatisticTypes;
    kpiSelected?: KpiTypes;
};

export default function EquipmentStatsLineGraph(props: EquipmentStatsLineGraphProps) {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const [chartFrequency, setChartFrequency] = useState<TimePeriodFrequencies | undefined>(undefined);
    const kpi = props.kpiSelected ?? settings.kpi.selectedKpi;
    const statistics = props.statistics ?? settings.kpi.statistic;


    const equipmentStatsOptions = { 
        ...getCustomKpisEquipmentStats(settings, session, props.kpis),
        calculateNodes: true,
        calculateActivityValues: true,
    };

    const [equipmentData, isEquipmentDataLoading] = useEquipmentAggregationTimeperiods({
        ...equipmentStatsOptions,
        frequency: chartFrequency,
        eventKeys: {
            ...session.project?.eventKeys ?? {},
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
        equipment: props.node ? [props.node] : [],
        sort: ["-timeperiodStartTime"]
    }, {
        disable: chartFrequency === undefined || props.node === undefined,
    });

    const kpiDefinition = getKpiDefinition(kpi, {settings, session});
    const unit = getUnit(kpiDefinition?.unit, statistics);

    if (kpiDefinition === undefined)
        return null;

    const propName = getEquipmentOverTimeProp(kpiDefinition, statistics);
    const equipmentChartData = useLineGraphData(equipmentData, propName, {scale: unit?.name === "percent" ? 100 : undefined});

    return <StatsLineGraph
        spotlightId={[buildKpiSpotlightId(kpiDefinition.id), "OverTime-Explanation-Workplace"]}
        frequency={chartFrequency}
        setFrequency={setChartFrequency}
        isLoading={isEquipmentDataLoading}
        data={equipmentChartData}
        unit={unit}
        kpi={kpi}
        statistics={statistics} />;
}

function getEquipmentOverTimeProp(kpiDefinition: KpiDefinition | undefined, statistic: StatisticTypes) {
    if (kpiDefinition === undefined)
        return "";
    if (isObject(kpiDefinition.equipmentOverTimeStatisticsPath))
        return get(kpiDefinition.equipmentOverTimeStatisticsPath, statistic) ?? "";
    return kpiDefinition.equipmentOverTimeStatisticsPath ?? "";
}
