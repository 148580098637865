import React, { useContext } from "react";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { AnalysisType, analysisGraphMapping, calculateGraphDisplayArguments, useGraph } from "../../hooks/UseGraph";
import i18n from "../../i18n";
import { DfgUtils, getCustomKpisDfg, isTerminalEdge } from "../../utils/DfgUtils";
import { CASE_TYPE_ID, Graph, groupingKeysNoneGroups, NodeRoles } from "../../models/Dfg";
import { Formatter } from "../../utils/Formatter";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { StatsRowPercentBar } from "../stats-section/StatsRowPercentBar";
import { StatsRowValue } from "../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../stats-section/StatsSection";
import { EdgeStats, FrequencyAndProductStats, NodeStats, isHighlighted } from "../stats-section/Utility";
import { LocalizationInfoStats } from "./LocalizationInfoStats";
import { SessionContext, hasNetTransitionTimes } from "../../contexts/SessionContext";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { LogStats } from "../../views/process-kpi-chart/stats/LogStats";
import { buildKpiSpotlightId, getKpiDefinition } from "../../models/Kpi";
import NodeStatsLineGraph from "../graph/NodeStatsLineGraph";

type SideStatisticsTimeProps = {
    disableProcessFocus?: boolean;
    disableCaseView?: boolean;
};

export default function SideStatisticsTime(props: SideStatisticsTimeProps) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === AnalysisType.Times)?.arguments;

    const graphOptions = {
        ...analysisArguments,
        ...getCustomKpisDfg(settings, session, false, KpiPresets.valueStreamTimeKpis),
        ...calculateGraphDisplayArguments,
    };
    const graph = useGraph(graphOptions, AnalysisType.Times, true);

    const objectType = props.disableProcessFocus ? CASE_TYPE_ID : settings.graph.objectType;
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const label = getSelectionLabel(graph, settings);

    const selectedEdge = DfgUtils.getEdgeFromGraph(graph, settings.selection.edge, isObjectCentric, objectType);
    const selectedNode = DfgUtils.getNodeFromGraph(graph, settings.selection.node, isObjectCentric, objectType);

    const isTerminalNodeSelected = Boolean(settings.selection.node?.role === NodeRoles.Start || settings.selection.node?.role === NodeRoles.End);

    const hasSelection = (settings.selection.node || settings.selection.edge);

    const node = selectedNode;
    const kpi = settings.kpi.selectedKpi;

    const kpiDefinition = getKpiDefinition(kpi, { settings, session });
    const isLoading = graph === undefined;

    const showNetEdgeTime = hasNetTransitionTimes(session) && [KpiTypes.NetTransitionTime, KpiTypes.NetThroughputTime].includes(settings.kpi.selectedKpi);

    return <>
        <div data-testid="sidePanel">
            {(!hasSelection || isTerminalNodeSelected) && <LogStats />}

            {((settings.selection.node !== undefined && !isTerminalNodeSelected) || settings.selection.edge !== undefined) && <StatsSection title={label} isTitleLoading={isLoading}>

                {/* Localization stats */}
                <LocalizationInfoStats />

                {/* Edge time stats */}
                {(settings.selection.edge !== undefined && kpi !== KpiTypes.Frequency && !DfgUtils.isAfterStartOrBeforeEndEdge(settings.selection.edge, graph?.nodes)) &&
                    <EdgeStats kpiType={showNetEdgeTime? KpiTypes.NetTransitionTime : KpiTypes.QueuingTime} edge={selectedEdge} isLoading={isLoading} />}

                {/* Frequency stats edge */}
                {settings.selection.edge && !isTerminalEdge(settings.selection.edge) && <StatsSubSection title="common.actionFrequency" spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge"])}>
                    <StatsRowValue label="common.statistics.absCount" isHighlight={isHighlighted(KpiTypes.Frequency, settings)} value={selectedEdge?.frequencyStatistics?.sum} unit={Formatter.units.number} isLoading={isLoading} />
                </StatsSubSection>}

                {/* Fallback: count, see issue https://gitlab.com/oniqofficial/general/oniq/-/issues/1993 */}
                {settings.selection.edge !== undefined && isTerminalEdge(settings.selection.edge) &&
                    !isObjectCentric && <StatsSubSection title={"common.actionFrequency"} spotlightId={buildKpiSpotlightId(KpiTypes.Frequency, ["Edge"])}>
                    <StatsRowValue label="common.statistics.absCount" isHighlight={isHighlighted(KpiTypes.Frequency, settings)} value={settings.selection.edge.edges.find(e => e.objectType === CASE_TYPE_ID)?.frequencyStatistics?.sum} isLoading={isLoading} />
                </StatsSubSection>}

                {/* Time stats for nodes */}
                {(settings.selection.node !== undefined && !isTerminalNodeSelected)
                    && <>
                        {/* Pass box */}
                        {!groupingKeysNoneGroups.includes(settings.groupingKey) &&
                            !!node?.frequencyStatistics?.sum && kpi !== KpiTypes.Frequency &&
                            <NodeStats kpiType={kpi} node={node} />}

                        {kpi === KpiTypes.BusyTime && !groupingKeysNoneGroups.includes(settings.groupingKey) && <StatsSubSection title="common.timePerformance" spotlightId={buildKpiSpotlightId(KpiTypes.BusyTime, ["Node"])} isTitleLoading={isLoading}>
                            <StatsRowValue label={i18n.t("common.statistics.sum")} value={node?.busyTimeStatistics?.total} unit={Formatter.units.durationShort} isHighlight={settings.kpi.statistic === StatisticTypes.Sum} isLoading={isLoading} />

                            {!!node?.busyTimeStatistics?.total && <>
                                {node?.productionTimeStatistics?.total !== undefined &&
                                    <StatsRowPercentBar label="common.production" value={node.productionTimeStatistics.total / node.busyTimeStatistics.total} />}

                                {node.setupTimeStatistics?.total !== undefined &&
                                    <StatsRowPercentBar label="common.setup" value={node.setupTimeStatistics.total / node.busyTimeStatistics.total} />}

                                {node.failureTimeStatistics?.total !== undefined &&
                                    <StatsRowPercentBar label="common.failure" value={node.failureTimeStatistics.total / node.busyTimeStatistics.total} />}

                                {node.interruptionTimeStatistics?.total !== undefined &&
                                    <StatsRowPercentBar label="common.interruption" value={node.interruptionTimeStatistics.total / node.busyTimeStatistics.total} />}
                            </>}
                        </StatsSubSection>}

                        {/* Line chart */}
                        {!!kpiDefinition?.nodeOverTimeStatisticsPath && <NodeStatsLineGraph />}

                        <FrequencyAndProductStats node={selectedNode} isLoading={isLoading} kpi={settings.kpi.selectedKpi}/>
                    </>}

            </StatsSection>}

        </div>
    </>;
}

export function getSelectionLabel(graph: Graph | undefined, settings: SettingsType, makePassIdFirst = false) {
    return settings.selection.edge
        ? `${DfgUtils.getNodeLabelById(settings.selection.edge.from, graph?.nodes, settings.groupingKey, makePassIdFirst)} ➞ ${DfgUtils.getNodeLabelById(settings.selection.edge.to, graph?.nodes, settings.groupingKey, makePassIdFirst)}`
        : settings.selection.node
            ? getShortActivityLabelFromActivityValues(settings.selection.node?.activityValues, settings.groupingKey, makePassIdFirst)
            : undefined;
}
