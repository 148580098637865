import { isNumber } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavigateFunction, generatePath, useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import Global, { getPreferences, setPreferences } from "../../Global";
import { useMatchedPath } from "../../Routing";
import updateUserpilotUrl from "../../utils/Userpilot";
import FilterEditor from "../filter-editor/FilterEditor";
import { ISidePanel, SidePanel } from "../side-panel/SidePanel";
import { Spotlight } from "../spotlight/Spotlight";
import Tabs, { TabStyles, TabType } from "../tabs/Tabs";
import { Dimension, DimensionProps } from "../dimension/Dimension";
import { IModal } from "../modal/Modal";
import { EditFavoritesModal } from "../../views/favorites/EditFavoritesModal";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import { ShareModal, shareAsync } from "../../utils/Share";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { TrayIconElement } from "../tray/TrayElement";
import { classNames } from "../../utils/Utils";
import { SetupMatrixCellProps } from "../matrix/SetupMatrixCell";
import { WorkplaceMatrixCellProps } from "../matrix/WorkplaceMatrixCell";
import i18n from "../../i18n";

export type BreadcrumbsType = {
    label: string;
    state?: Partial<SettingsType>;
    url?: string;
}

export type TabbedViewType = {
    tabTitle: string;
    tabSlug?: string;
    stats: JSX.Element | JSX.Element[] | undefined | null;
    controller: JSX.Element | JSX.Element[] | undefined | null;
    content: JSX.Element | JSX.Element[] | undefined;
    isVisible?: boolean;
    spotlightId?: string;

    /**
     * @deprecated The spotlight is now part of the stats view. The global spotlight
     * will be removed in the future.
     */
    statsSpotlightId?: string;

    /**
     * When provided, these dimensions will be shown in the dimension bar above
     * the tabs. If not provided, the dimension bar will be hidden.
     */
    dimensions?: DimensionProps;

    /**
     * Executed when this tab page comes live
     */
    activator?: (previousSettings: DeepPartial<SettingsType> | undefined) => void;

    /**
     * Executed when the selection changes. This function should return true
     * if you want to switch to the stats side panel, otherwise false.
     */
    selectionTrigger?: (settings: SettingsType) => boolean;

    /**
     * Invoked when the user added or changed a filter in the filter editor
     */
    onFiltersComitted?: () => void;

    /**
     * If true, execution of the activator will be delayed until false
     */
    isInitializing?: boolean;

    /**
     * If true, the sharing button will be hidden
     */
    disableSharing?: boolean;

    subtitle?: JSX.Element | string | undefined;

    breadcrumbs?: BreadcrumbsType[];
}

export type TabbedViewProps = {
    pages: TabbedViewType[];
    onTabChanged?: (idx: number, slug: string) => void;
    hideFilter?: boolean;
    enableOnlyTimespan?: boolean;
    isFavorable?: boolean;
    breadcrumbs?: BreadcrumbsType[];

    subtitle?: JSX.Element | string | undefined;
};

export function TabbedView(props: React.PropsWithChildren<TabbedViewProps>) {
    // Initialize parameters from URL
    const { projectId, tabSlug } = useParams<{
        projectId: string,
        tabSlug: string,
    }>();

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    if (session.projectId !== projectId)
        session.setProject(projectId);

    const { trackEvent } = useMatomo();

    const addFavoritesModalRef = useRef<IModal>(null);
    const [showShareModal, setShowShareModal] = useState(false);

    const visiblePages = props.pages.filter(p => p.isVisible !== false);

    updateUserpilotUrl(true);

    // Initialized from params, updated at tab changes. Always has index
    // of currently displayed tab page
    let currentTabIdx = visiblePages.findIndex(p => p.tabSlug === tabSlug);

    if (tabSlug !== undefined && currentTabIdx < 0 && isNumber(+tabSlug))
        currentTabIdx = Math.max(0, Math.min(visiblePages.length - 1, (+tabSlug) - 1));
    if (currentTabIdx < 0)
        currentTabIdx = 0;

    const matchedPath = useMatchedPath();
    const navigate = useNavigate();

    const sidePanelRef = useRef<ISidePanel>(null);
    Global.sidePanelRef = sidePanelRef;

    // Persist settings in local storage
    const [isInitialized, setIsInitialized] = useState(false);

    const pages: TabType[] = visiblePages.map(p => {
        return {
            label: p.tabTitle,
            isHidden: p.isVisible === false,
        };
    });

    function getPrefs() {
        let result = {...(getPreferences() ?? {})};

        // This is a workaround to deal with the fact that react executes
        // useEffect hooks in development mode twice, and we don't know
        // when to reset the queue.
        for (const element of preValidationSettingsModifierQueue)
            result = ObjectMerger.mergeObject(result, element);

        preValidationSettingsModifierQueue.length = 0;

        return result;
    }

    useEffect(() => {
        // Don't run activators unless the project is fully initialized
        if (session.project === undefined || isInitialized || visiblePages?.[currentTabIdx]?.isInitializing)
            return;

        if (visiblePages?.[currentTabIdx]?.activator !== undefined)
            visiblePages[currentTabIdx].activator!(getPrefs());

        setIsInitialized(true);
    }, [
        visiblePages.map(p => p.isInitializing).join(" "),
        JSON.stringify(session.project),
        currentTabIdx,
    ]);

    useEffect(() => {
        if (isInitialized) {
            setPreferences(settings);
        }
    }, [
        JSON.stringify(settings),
    ]);


    const isSharedProject = !!session.project?.isSharedWithOrganization;

    // Switch to stats page in case selection changed
    useEffect(() => {
        const page = visiblePages[currentTabIdx];
        if (!isInitialized || !page)
            return;

        if (page.selectionTrigger &&
            page.selectionTrigger(settings) &&
            sidePanelRef.current)
            sidePanelRef.current.showPage(1);
    }, [
        // Just add dependencies for what actually would describe
        // a selection change. When the instance of e.g. a node changes,
        // but the node id remains the same, the selection trigger should
        // NOT be executed.
        settings.selection.node?.id,
        settings.selection.edge?.from,
        settings.selection.edge?.to,
        settings.selection.product?.id,
        settings.selection.case,
        settings.selection.category,
        settings.selection.categoryValue,
        settings.selection.feature,
        settings.selection.setupTransition,
        settings.selection.machine,
        settings.selection.timeperiod,
        (settings.selection.matrixElement as SetupMatrixCellProps)?.transition?.from,
        (settings.selection.matrixElement as SetupMatrixCellProps)?.transition?.to,
        (settings.selection.matrixElement as WorkplaceMatrixCellProps)?.workplace?.name,
        (settings.selection.matrixElement as WorkplaceMatrixCellProps)?.kpi,
    ]);

    if (visiblePages[currentTabIdx] === undefined || session.project === undefined)
        return <></>;
    const page = visiblePages[currentTabIdx];

    const hasDimensionSwitch = page.dimensions !== undefined;
    const sidePanelPages = [{
        label: "common.controls",
        className: "controlsButton",
        icon: "controls",
        isHidden: !page.controller,
    }, {
        label: "explorer.stats",
        className: "statisticsButton",
        icon: "statistics",
        isHidden: !page.stats,
    }];
    const hasSidePanel = sidePanelPages.some(p => !p.isHidden);

    const subtitle = visiblePages[currentTabIdx].subtitle ?? props.subtitle;
    const breadcrumbs = visiblePages[currentTabIdx].breadcrumbs ?? props.breadcrumbs ?? [];

    return <div className={classNames(["tabbedView", settings.filterEditor.showFilterEditor && "filterExpanded", props.hideFilter && "hideFilter", !hasSidePanel && "hideAside"])}>
        {(breadcrumbs?.length || hasDimensionSwitch) && <div className="topBar">
            <div className="breadcrumbs">
                {breadcrumbs.map((b, idx) => {
                    return <div key={(b.url ?? b.label ?? "") + idx.toString()} className="breadcrumb">
                        {(b.url !== undefined || b.state !== undefined) && <a
                            onClick={() => {
                                if (b.state !== undefined)
                                    settings.set(b.state);

                                if (b.url !== undefined)
                                    navigate(b.url, { replace: true });
                            }}
                        >{i18n.t(b.label ?? "")}</a>}
                        {(b.url === undefined && b.state === undefined) && i18n.t(b.label ?? "")}
                    </div>;
                })}
            </div>

            {hasDimensionSwitch && <Dimension {...page.dimensions} />}
        </div>}
        <div className="tabViewContainer">
            {subtitle !== undefined && <div className="tabTitle">
                {typeof subtitle === "string" ? <div className="text">{subtitle}</div> : subtitle}
            </div>}
            <Tabs
                tabStyle={TabStyles.InlinePage}
                body={props.children}
                tabClassName={props.hideFilter ? "hideFilter" : ""}
                pages={pages}
                selectedIndex={currentTabIdx}
                onChanged={(_, idx) => {
                    const tabSlug = visiblePages[idx].tabSlug ?? idx.toString();
                    setIsInitialized(false);
                    const path = generatePath(matchedPath.route!, { projectId: projectId!, tabSlug: tabSlug ?? idx.toString() });
                    navigate(path, { replace: true });
                    visiblePages[idx]?.activator?.(getPrefs());

                    if (props.onTabChanged)
                        props.onTabChanged(idx, tabSlug);
                }}
            >
                {visiblePages.map(p => {
                    if (p.tabSlug !== tabSlug)
                        return null;

                    return <div key={p.tabTitle} className="tabPanel">
                        <div className="tray"></div>

                        <TrayIconElement
                            title="favorites.saveView"
                            icon="radix-star"
                            id="favoriteView"
                            iconClass="svgFill"
                            onClick={() => {
                                addFavoritesModalRef.current?.show();
                            }}
                            order={1000}
                        />

                        {isSharedProject && !p.disableSharing && <TrayIconElement
                            title="common.shareView"
                            icon="radix-share-1"
                            id="shareView"
                            onClick={async () => {
                                const sharingWorked = await shareAsync(session, settings);

                                trackEvent({
                                    category: "Interactions",
                                    action: "Shared view",
                                    name: sharingWorked ? "navigator" : "fallback",
                                });

                                setShowShareModal(!sharingWorked);
                            }}
                            order={1001}
                        />}

                        {isInitialized && p.content}

                        {p.spotlightId !== undefined && <Spotlight id={p.spotlightId} className="bottomRight" />}
                    </div>;
                })}

            </Tabs>
        </div>

        <SidePanel
            ref={sidePanelRef}
            pages={sidePanelPages}
        >
            {/* Controls */}
            {isInitialized && <div>
                {page.controller}
            </div>}

            {/* Stats */}
            {isInitialized && <div>
                {page.stats}
                {page.statsSpotlightId !== undefined && <Spotlight id={page.statsSpotlightId!} className="bottomRight fixed" />}
            </div>}
        </SidePanel>

        {!props.hideFilter && <FilterEditor
            enableOnlyTimespan={props.enableOnlyTimespan}
            onFiltersCommitted={() => {
                if (page.onFiltersComitted)
                    page.onFiltersComitted();
            }}
        />}
        <EditFavoritesModal ref={addFavoritesModalRef} />
        {showShareModal && <ShareModal onDone={async () => setShowShareModal(false)} />}
    </div>;
}


const preValidationSettingsModifierQueue: DeepPartial<SettingsType>[] = [];

export function navigateWith(settings: SettingsContextType, navigate: NavigateFunction, settingChanges: DeepPartial<SettingsType>, url: string, replace = false) {
    if (settingChanges) {
        registerPreferenceChange(settingChanges);
        settings.mergeSet({
            ...settingChanges
        });
    }

    navigate(url, { replace });
}

function registerPreferenceChange(settingChanges: DeepPartial<SettingsType>) {
    preValidationSettingsModifierQueue.push(settingChanges);
}