import { PerTimeperiodEquipmentStatisticsParams, PerTimeperiodEquipmentStatisticsSchema, TimePeriodFrequencies, disableAllCalcOptions } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { useContext } from "react";
import { EventKeys } from "../models/EventKeys";
import { defaultApiLimit } from "./UseCaseStatistics";
import { useApi } from "./UseApi";
import { Datastores } from "../utils/Datastores";

export function useEquipmentAggregationTimeperiods(request?: Partial<PerTimeperiodEquipmentStatisticsParams>, options?: {
    disable?: boolean,
}): [PerTimeperiodEquipmentStatisticsSchema | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    if (request?.equipment === undefined)
        throw new Error("request.equipment must be defined");

    const requestOptions: PerTimeperiodEquipmentStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        equipment: request.equipment ?? [],
        eventFilters: request?.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request?.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys,
        frequency: request?.frequency ?? TimePeriodFrequencies.Day,
        uploadId: request?.uploadId ?? session.project?.uploadId ?? "",
        uploads: request?.uploads ?? session.project?.uploads,
        tz: request?.tz ?? session.timezone,
        limit: request?.limit ?? defaultApiLimit,
    };

    return useApi(
        Datastores.getEquipmentAggregationTimeperiods,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || 
                session.project === undefined,
        });
}