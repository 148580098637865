import React, { useContext, useRef } from "react";
import BenchmarkingKpiControls, { getValidBenchmarkingKpiControlSettings } from "../../components/controls/BenchmarkingKpiControls";
import { ControlFlavor, Controls } from "../../components/controls/Controls";
import DfgControls, { getValidDfgControlSettings } from "../../components/controls/DfgControls";
import { AutoCenteringModes, IDfGraph, ProcessGraph, ZoomControlLocations, getEdgeColor, getEdgeHighlightColor, nodeHighlightColorMapDefault } from "../../components/dfg/DfGraph";
import { getNodeMarkupOutput } from "../../components/dfg/nodes/NodeMarkupFactory";
import { Dimensions, getDimensionParameters } from "../../components/dimension/Dimension";
import SideStatisticsCaseGantt from "../../components/side-statistics/SideStatisticsCaseGantt";
import SideStatisticsOutput from "../../components/side-statistics/SideStatisticsOutput";
import { TabbedView, TabbedViewType } from "../../components/tabbed-view/TabbedView";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import { AnalysisType } from "../../hooks/UseGraph";
import i18n from "../../i18n";
import { CaseGanttSetting } from "../../models/ApiTypes";
import { getAllowedKpis, graphKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { getEdgeLabelText, getEdgeStat, getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import { allowedGroupingReduced, allowedGroupingReducedWithoutMachineObject, allowedGroupingWithoutObjectType } from "../../utils/GroupingUtils";
import { getValueStreamDefaultSettings, viewSettingsInitialization } from "../../utils/Initializers";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import CaseGantt from "../gantt/CaseGantt";
import { ProcessVariants } from "../process-path/ProcessVariants";
import { EdgeVarianceChart } from "../../components/kpi-chart/EdgeVarianceChart";
import EdgeKpiChart from "../../components/kpi-chart/EdgeKpiChart";
import { NodeVarianceChart } from "../../components/kpi-chart/NodeVarianceChart";
import { NodeKpiChart } from "../../components/kpi-chart/NodeKpiChart";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function OutputProcessView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = quantities.map(q => q.baseQuantity);
    const dfgRef = useRef<IDfGraph>(null);

    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);

    const controllerSuffix = (hasObjects ? "-OC" : "");

    const views: TabbedViewType[] = [{
        tabTitle: "explorer.dfg",
        tabSlug: "dfg",
        controller: <>
            <DfgControls kpis={KpiPresets.valueStreamOutputKpis} />
        </>,
        selectionTrigger: (settings: SettingsType) => {
            return settings.selection.node !== undefined || settings.selection.edge !== undefined;
        },
        stats: <SideStatisticsOutput showOutput={true} showYield={true} showScrap={true} showThroughput={true} />,
        spotlightId: "Output-DFG",
        activator: (preferences) => {
            let temp = viewSettingsInitialization(session,
                settings,
                preferences,
                getValueStreamDefaultSettings(session),
                {
                    aggregationTypes: [AggregationTypes.Product],
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamOutputKpis),
                    quantities: baseQuantities,
                    groupingKeys: allowedGroupingReduced,
                    statistics: [StatisticTypes.Mean, StatisticTypes.Sum],
                });

            if (!temp)
                return;

            temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamOutputKpis }));

            settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
            }));

        },
        dimensions: getDimensionParameters(session.projectId, "process/dfg", undefined, []),
        content: <div className="processExplorerGraph" key="processExplorerGraph" >
            <ProcessGraph
                ref={dfgRef}
                legend={{
                    leftLabel: "common.low",
                    rightLabel: "common.high",
                    colormap: nodeHighlightColorMapDefault,
                }}
                isObjectCentric={hasObjects}
                zoomControlLocation={ZoomControlLocations.FarRight}
                analysis={AnalysisType.Output}
                edgeLabelFunc={getEdgeLabelText}
                edgeColorFunc={edge => getEdgeColor(edge, settings, session)}
                nodeHighlightStatFunc={(node) => {
                    return getMainNodeStat(node, settings, session);
                }}
                nodeHighlightColorFunc={(_node, _stat, _minStat, _maxStat, scale) => {
                    if (scale !== undefined)
                        return nodeHighlightColorMapDefault(scale);
                }}
                edgeHighlightStatFunc={(edge) => {
                    return getEdgeStat(edge, settings, session);
                }}
                edgeHighlightColorFunc={(edge, stat, minStatistic, maxStatistic, scale) => {
                    if (scale !== undefined)
                        return getEdgeHighlightColor(edge, scale, settings, session);
                }}
                markupFunc={getNodeMarkupOutput}
                centerMode={AutoCenteringModes.CenterIfUninitialized} />
            <BackButtonTrayElement />
            <DownloadDfgTrayElement graph={dfgRef.current} filename={i18n.t("common.processGraph").toString() + "-" + i18n.t("common.output").toString()} />
        </div>,
    }, {
        tabTitle: "workflows.processPath.processVariants",
        tabSlug: "process-path",
        selectionTrigger: (settings) => {
            return !!(settings.selection.node || settings.selection.edge);
        },
        activator: (preferences) => {
            let temp = viewSettingsInitialization(session,
                settings,
                preferences,
                getValueStreamDefaultSettings(session),
                {
                    aggregationTypes: [AggregationTypes.Product],
                    kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamOutputKpis),
                    quantities: baseQuantities,
                    groupingKeys: allowedGroupingReduced,
                });

            if (!temp)
                return;

            temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamOutputKpis }));

            settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
            }));
        },
        stats: <SideStatisticsOutput showOutput={true} showYield={true} showScrap={true} showThroughput={true} />,
        controller: <>
            <DfgControls kpis={KpiPresets.valueStreamOutputKpis} />
        </>,
        spotlightId: "Output-Variance-ProcessPath",
        dimensions: {
            timing: `/projects/${session.projectId}/timings/process/process-path`,
            output: `/projects/${session.projectId}/output/process/process-path`,
        },
        content: <>
            <ProcessVariants
                analysisType={AnalysisType.Output}
            />
        </>,
    }, {
        tabTitle: "common.processKpis",
        tabSlug: "benchmarking",
        selectionTrigger: (settings) => {
            return !!settings.selection.node || !!settings.selection.edge;
        },
        controller: <BenchmarkingKpiControls kpis={KpiPresets.valueStreamOutputBenchmarkingKpis} />,
        stats: <SideStatisticsOutput showOutput={true} showYield={true} showScrap={true} showThroughput={true} />,
        activator: (preferences) => {
            let temp = viewSettingsInitialization(session, settings, preferences, getValueStreamDefaultSettings(session), {
                aggregationTypes: [AggregationTypes.Product],
                quantities: baseQuantities,
                sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.OrderSequences],
                kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamOutputBenchmarkingKpis),
                groupingKeys: allowedGroupingReducedWithoutMachineObject,
            });

            if (!temp)
                return;

            temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingKpiControlSettings(session, temp, { kpis: KpiPresets.valueStreamOutputBenchmarkingKpis }));

            const allowedComparisons = getEnabledComparisonsValueStream(session, temp);

            // The allowed comparisons are depending on settings that might have been corrected above,
            // so we need to run this as a two-step process
            settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                sortBy: allowedComparisons.includes(KpiComparisons.Planning) ? [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.OrderSequences] : undefined,
                statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
            }));
        },
        spotlightId: "Output-KPI",
        content: <>
            {[KpiTypes.GoodQuantityTransport, KpiTypes.ThroughputRateTransport, KpiTypes.FrequencyTransport].includes(settings.kpi.selectedKpi) &&
                <>
                    {settings.kpi.statistic === StatisticTypes.Variance ?
                        <EdgeVarianceChart analysisType={AnalysisType.Output} /> :
                        <EdgeKpiChart analysisType={AnalysisType.Output} />}

                </>}
            {![KpiTypes.GoodQuantityTransport, KpiTypes.ThroughputRateTransport, KpiTypes.FrequencyTransport].includes(settings.kpi.selectedKpi) && <>
                {settings.kpi.statistic === StatisticTypes.Variance ?
                    <NodeVarianceChart analysisType={AnalysisType.Output} noDataPlaceholder="" pageSlug="output" /> :
                    <NodeKpiChart analysisType={AnalysisType.Output} noDataPlaceholder="" pageSlug="output" />}
            </>}

            <BackButtonTrayElement />
        </>,
        dimensions: getDimensionParameters(session.projectId, "process/benchmarking", undefined, []),
    }];

    // Show case gantt only if there's case yield data assigned
    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);
    if (caseYieldQuantities.length > 0)
        views.push({
            tabTitle: "explorer.caseGantt",
            tabSlug: "case-gantt",
            spotlightId: "Output-CaseGantt",
            controller: <>
                <Controls flavor={ControlFlavor.CaseGanttControls} />
            </>,
            activator: (preferences) => {
                // Default case gantt selection is CaseGanttSetting.Yield
                const temp = viewSettingsInitialization(session,
                    settings,
                    preferences,
                    { kpi: { comparisons: KpiComparisons.None } },
                    {
                        aggregationTypes: [AggregationTypes.Product],
                        quantities: baseQuantities,
                        caseGanttSettings: caseYieldQuantities.length > 0 ? [CaseGanttSetting.Yield, CaseGanttSetting.YieldRate] : [CaseGanttSetting.Duration],
                        groupingKeys: allowedGroupingWithoutObjectType
                    });

                if (!temp)
                    return;

                settings.set(viewSettingsInitialization(session, temp, undefined, undefined, {
                    statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                }));
            },
            selectionTrigger: (settings: SettingsType) => {
                return settings.selection.case !== undefined;
            },
            statsSpotlightId: "Output-CaseGantt-Statistics" + (settings.selection.case === undefined ? "-NoSelection" : ("-Case" + controllerSuffix)),
            stats: settings.selection.case !== undefined ? <SideStatisticsCaseGantt /> : <SideStatisticsOutput disableProcessFocus={true} showOutput={true} showYield={true} showScrap={true} showThroughput={true} />,
            dimensions: getDimensionParameters(session.projectId, "process/case-gantt", [Dimensions.Timing, Dimensions.Output]),
            content: <div className="fillParent" style={{ display: "flex" }} key="energyCaseGantt">
                <CaseGantt />
                <BackButtonTrayElement />
            </div>,
        });

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.valueStream"
        }]}
        pages={views} />;
}
