import React, { useContext } from "react";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { KpiComparisons } from "../../contexts/ContextTypes";
import i18n from "../../i18n";
import { isFunction } from "lodash";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import { KpiHighlightControls } from "./HighlightControls";

type KpiComparisonControlProps = {
    /**
     * List of comparisons that are enabled. Can also be a callback, and you can nicely plug
     * getEnabledComparisons in here.
     */
    enabledComparisons?: KpiComparisons[] | ((session: SessionType, settings: SettingsType) => KpiComparisons[]);

    /**
     * When set to true, a KpiHighlightControl will be shown
     * if a "highlightable" comparison value is selected,
     * and that comparison value can be selected
     */
    showHighlightControls?: boolean;
};

/**
 * The kpi comparison controls allow the user to select the comparison type.
 * If no planning data exists, the planning option is automatically removed from visibleComparisons.
 */
export function KpiComparisonControls(props: KpiComparisonControlProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const location = useLocation();

    const hasPlanning = session.project?.uploadIdPlan !== undefined ||
        session.project?.uploads?.routings !== undefined;

    const enabledComparisons = (props.enabledComparisons ?
        (isFunction(props.enabledComparisons) ? props.enabledComparisons(session, settings) : props.enabledComparisons) :
        [KpiComparisons.BestProcesses, KpiComparisons.Planning, KpiComparisons.None]).filter(a => a !== KpiComparisons.Planning || hasPlanning);

    const options = enabledComparisons.map(v => { return { label: i18n.t(`kpiComparisons.${v.toString()}`), value: v }; });
    const isSpotlightAggregationDependant = location.pathname.includes("/kpis/");

    const selectedOption = {
        value: settings.kpi.comparisons,
        label: i18n.t(`kpiComparisons.${settings.kpi.comparisons.toString()}`),
    };

    const isSelectedOptionSelectable = options.some(o => o.value === selectedOption.value);

    if (options.length > 0)
        return <>
            <div className="section comparison controls" data-testid="comparison-controls">
                <div className="title">
                    {i18n.t("common.kpiComparison")}
                    <Spotlight
                        id={buildControllerSpotlightId(
                            location.pathname,
                            isSpotlightAggregationDependant ?
                                ["comparison", settings.kpi.aggregation.toString()] :
                                ["comparison"]
                        )}
                        className="mls" />
                </div>
                <Dropdown
                    options={options}
                    value={selectedOption}
                    onChange={(e) => {
                        const comparison = e!.value as KpiComparisons;
                        settings.setKpiState({ comparisons: comparison });
                    }}
                />
            </div>

            {props.showHighlightControls &&
                isSelectedOptionSelectable &&
                settings.kpi.comparisons === KpiComparisons.Planning &&
                <KpiHighlightControls />}
        </>;

    return null;
}
