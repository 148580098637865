import { get } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import { CaseDeviationStatisticsSchema } from "../../models/ApiTypes";
import { Bar } from "../../components/graph/Graph";
import { ContainerModes, commonSelectionLineProps } from "../../components/graph/GraphCommon";
import { GroupGraph } from "../../components/graph/GroupGraph";
import Spinner from "../../components/spinner/Spinner";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { CaseStatistics } from "../../models/Case";
import { KpiChartParams } from "./ProcessKpiChart";
import { getLegend } from "./ProductProcessKpiChart";
import { SessionContext, SessionType, hasDownloadPermission } from "../../contexts/SessionContext";
import { NotificationService } from "../../components/notification/NotificationService";
import DownloadFile, { TemplateType } from "../../components/download-file/DownloadFile";
import { getKpiDefinition, getUnit } from "../../models/Kpi";
import { getAxisLabel, getBarColor } from "../../components/product-chart/ProductChart";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { getPropName, useKpiCaseStatisticsWithDeviation } from "../../hooks/UseKpiCaseStatisticsWithDeviation";
import { useStatistics } from "../../hooks/UseStatistics";
import { KpiTypes } from "../../models/KpiTypes";
import { useGridResize } from "../../components/kpi-chart/UseGridResize";
import Toast from "../../components/toast/Toast";
import { defaultApiLimit } from "../../hooks/UseCaseStatistics";
import { getDefaultEnabledComparisons } from "../../utils/SettingsUtils";

export function CaseProcessKpiChart(props: KpiChartParams & {
    noDataPlaceholder: string,
    addEnergyStats?: boolean,

    // Set this to true if the stats the API returns are valid even if
    // we filter for a single object only. Defaults to `true`.
    singleObjectStatsValid?: boolean,
}) {
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });

    const [isToastClosed, setIsToastClosed] = useState(false);

    const container = useRef<HTMLDivElement>(null);
    const [width] = useGridResize(container, 1, undefined, undefined, 0);

    const [caseStats, isCaseStatsLoading, caseDeviation, isCaseDeviationLoading] = useKpiCaseStatisticsWithDeviation(props.addEnergyStats);

    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);

    const showPlanningData = settings.kpi.comparisons === KpiComparisons.Planning || !!kpiDefinition?.useDeviationApi;
    const isDeviationApi = showPlanningData &&
        session?.project?.uploadIdPlan !== undefined &&
        session?.project?.eventKeysPlan !== undefined;

    const selectedCaseFromData = caseStats?.cases?.find(x => x.id === settings.selection.case);

    const selectedCaseDeviationFromData = caseDeviation?.cases?.find(x => x.id === settings.selection.case);

    const [stats,] = useStatistics(undefined);

    const isComparisonAllowed = getDefaultEnabledComparisons(session, settings)?.includes(settings.kpi.comparisons) ?? false;

    // Indicates if the selected case wasn't found in the data returned
    // by the hook above. If this is the case, we either issue a request
    // for that specific case, or show a notification if filtering would
    // fault the stats.
    const selectedCaseMissingInResults = settings.selection.case !== undefined &&
        (caseStats?.cases !== undefined && caseStats?.cases.length === defaultApiLimit ||
            caseDeviation?.cases !== undefined && caseDeviation?.cases.length === defaultApiLimit) &&
        selectedCaseFromData === undefined && selectedCaseDeviationFromData === undefined;


    // Notification is deactivated for the carbon bar chart    
    const [showNotification, setShowNotification] = useState<boolean>(true);
    const showSelectionLineWarning = selectedCaseMissingInResults &&
        showNotification &&
        props.singleObjectStatsValid === false && props.addEnergyStats === undefined;

    if (showSelectionLineWarning) {
        NotificationService.add({
            id: "Selected-case-line-invisible",
            summary: i18n.t("kpi.selectionLineCaseIsInvisibleTitle"),
            message: <p>{i18n.t("kpi.selectionLineCaseIsInvisible")}</p>,
            className: "light warning-accent",
            icon: "radix-bell",
            autoCloseDelay: 7000,
        });
        setShowNotification(false);
    }

    // Display a toast to inform the user that orders with no planning data are not displayed in the planning graph
    // Number of orders in the filter should not exceed the limit of orders we display in the graph
    // to make sure that all selected orders in the filter has to be displayed in the planning graph
    const delta = stats?.numFilteredTraces !== undefined && stats?.numFilteredTraces <= defaultApiLimit && caseDeviation?.cases !== undefined ? stats?.numFilteredTraces - caseDeviation?.cases.length : 0;
    const hasMissingPlanningCases = isDeviationApi && delta > 0;

    //Draw selection line
    const selectedCase = (isDeviationApi || settings.kpi.comparisons === KpiComparisons.Planning) ?
        selectedCaseDeviationFromData : selectedCaseFromData;

    const selectionLine = useMemo(() => {
        if (selectedCase) {
            const getSelectedCase = (isDeviationApi && !kpiDefinition?.useDeviationApi) ? get(selectedCase, "actual") : selectedCase;
            const value = getSelectedCase ? getStats(getSelectedCase, session, settings) : undefined;
            return value !== undefined ? [{ ...{ value: value, ...commonSelectionLineProps } }] : [];
        }
        return undefined;
    }, [
        selectedCase,
        isDeviationApi,
        settings.kpi.analyzedValue,
        settings.quantity
    ]);

    const caseData = isDeviationApi ? caseDeviation?.cases : caseStats?.cases;

    const isComparisonHighlightingEnabled = settings.kpi.comparisons === KpiComparisons.Planning && settings.kpi.highlightDeviations;

    const data: Bar<CaseStatistics | CaseDeviationStatisticsSchema>[][] = useMemo(() => {
        if (!caseData?.length)
            return [];


        const result: Bar<CaseStatistics | CaseDeviationStatisticsSchema>[][] = [];
        //if data is updated, then toast should be updated as well
        setIsToastClosed(false);

        for (const c of caseData ?? []) {
            const actualCase = (isDeviationApi && !kpiDefinition?.useDeviationApi) ? get(c, "actual") : c;
            if (!actualCase)
                continue;
            const value = getStats(actualCase, session, settings);
            if (value === undefined)
                continue;

            const group: Bar<CaseStatistics | CaseDeviationStatisticsSchema>[] = [{
                value,
                data: c,
                label: c.id,
            }];

            // Find corresponding case deviation
            if (isComparisonAllowed && isDeviationApi && settings.kpi.comparisons === KpiComparisons.Planning) {
                const plannedCase = get(c, "planned");
                if (!plannedCase)
                    continue;
                const comparisonValue = getStats(plannedCase, session, settings);
                if (isFinite(comparisonValue))
                    group.push({
                        value: isFinite(comparisonValue) ? comparisonValue : 0,
                        valueLabel: !isFinite(comparisonValue) ? i18n.t("common.notAvailableAbbreviated").toString() : undefined,
                        data: c,
                        label: c.id,
                    });

                if (isComparisonHighlightingEnabled)
                    group[0].barColor = getBarColor(kpiDefinition, value, comparisonValue);
            } else
            if (settings.kpi.comparisons !== KpiComparisons.None)
                group.push({
                    value: 0,
                    valueLabel: i18n.t("common.notAvailableAbbreviated").toString(),
                    data: c,
                    label: c.id,
                });

            result.push(group);
        }
        return result;
    }, [
        settings.kpi.comparisons,
        isComparisonHighlightingEnabled,
        caseDeviation,
        caseStats,
        settings.kpi.aggregation,
        settings.kpi.selectedKpi,
        settings.quantity,
        settings.kpi.sortOrder,
    ]);

    const downloadAllowed = hasDownloadPermission(session);

    const isLoading = isCaseStatsLoading || isCaseDeviationLoading;

    return <div ref={container}>
        <Spinner isLoading={isLoading} showProjectLoadingSpinner={true} />
        {!isLoading && <><GroupGraph
            {...props}
            title={i18n.t(props.titleKey).toString()}
            horizonalLines={settings.selection.case ? selectionLine : []}
            padding={{
                top: 85,
                left: 60,
                bottom: 100,
            }}
            selectedGroupIdx={data.findIndex(d => d[0].data?.id === settings.selection.case)}
            selectedGroupBarIdx={0}
            onLabelSelected={(groupIdx) => {
                const element = data[groupIdx][0].data;
                if (settings.selection.case === element?.id)
                    settings.setSelection({});
                else
                    settings.setSelection({
                        case: element?.id
                    });
            }}
            onSelected={(groupIdx, barIdx, data) => {
                if (settings.selection.case === data?.id)
                    settings.setSelection({});
                else
                    settings.setSelection({
                        case: data?.id
                    });
            }}
            barPadding={10}
            minGroupPadding={50}
            showYAxisLines={true}
            yAxisLabel={getAxisLabel(settings.kpi.selectedKpi, settings.kpi.statistic, session, settings)}
            showBarValues={true}
            yAxisUnit={unit}
            formatterParams={{
                numDigits: 1,
                baseQuantity: settings.quantity
            }}
            data={data}
            containerMode={ContainerModes.Constrained}
            legend={getLegend(settings, isComparisonHighlightingEnabled)}
        />

        <div className="toastContainer" style={{ width: width }}>
            <Toast visible={!isToastClosed && hasMissingPlanningCases} className="toastCondensed" onClose={() => setIsToastClosed(true)} >
                {i18n.t("kpi.missingPlanningCases", {
                    count: delta,
                }).toString()}
            </Toast>
        </div>

        <DownloadFile
            data={data}
            planningData={settings.kpi.comparisons === KpiComparisons.Planning}
            template={TemplateType.Case}
            meta={unit}
            allowed={downloadAllowed}
            title={i18n.t(props.titleKey).toString()} />
        </>}
    </div>;
}

/**
 * Returns a value for the selected KPI. If the KPI is order count, we always return 1.
 */
export function getStats(stats: CaseStatistics | CaseDeviationStatisticsSchema, session: SessionType, settings: SettingsType) {
    const propName = getPropName(session, settings);
    // For order count in the case stats we always return 1
    if (settings.kpi.selectedKpi === KpiTypes.OrderCount)
        return 1;
    return propName ? get(stats, propName) : undefined;
}

export function exportCaseData(data: Bar<CaseDeviationStatisticsSchema | CaseStatistics>[][], planningData?: boolean) {
    const result = [];

    for (const bar of data) {
        const item = {
            [i18n.t("common.case")]: bar[0]?.label,
            [i18n.t("common.actual")]: bar[0]?.value,
        };
        if (planningData)
            item[i18n.t("common.plan")] = bar[1]?.value;

        result.push(item);
    }

    return result;
}
